import {useStoreContext} from '@store/store-context';
import {BillingType, WorkspaceUseCase} from './interface';

export const useWorkspaceUseCase = () => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const useCase = auth.user.workspace.settings.useCase;

  const isBillingStandard =
    auth.user.workspace.billing.activeTrial &&
    !auth.user.workspace.billing.trailCompleted
      ? true
      : auth.user.workspace.billing.plan === BillingType.STANDARD_MONTHLY ||
        auth.user.workspace.billing.plan === BillingType.STANDARD_YEARLY;

  const isPerformanceActive =
    (useCase === WorkspaceUseCase.PERFORMANCE ||
      useCase === WorkspaceUseCase.ALL) &&
    isBillingStandard;

  const isEngagementActive =
    useCase === WorkspaceUseCase.ENGAGEMENT || useCase === WorkspaceUseCase.ALL;

  const isCheckinsEnabled =
    auth.user.workspace.settings.checkInStatus === 'enabled';

  const isActionItemEnabled =
    auth.user.workspace.settings.actionItemStatus === 'enabled' &&
    isPerformanceActive;

  const isFeedBackEnabled = auth.user.workspace.settings.feedback === 'enabled';

  const isOneOnOneEnabled = auth.user.workspace.settings.oneOnOne === 'enabled';

  return {
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
    isBillingStandard,
    isActionItemEnabled,
    isFeedBackEnabled,
    isOneOnOneEnabled,
  };
};
