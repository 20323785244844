import {useStoreContext} from '@store/store-context';
import {Body2} from '@ui/atoms/typography';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {GroupsSelect} from '@ui/molecules/select/group-select/group-select';
import {Flex, FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {userName} from '@utils/user-name';
import React, {useMemo, useState} from 'react';
import {Button} from '@ui/atoms/button';
import {FiChevronDown} from 'react-icons/fi';
import {SearchBox} from '@ui/molecules/search-box';
import {groupUsers} from '@utils/users';
import {FilterIcon} from '@ui/atoms/icons/filter-icon';
import {updateActionItemsFilter} from '@utils/firebase-request';
import {DropdownItem} from '../you/you-page.styles';

interface GoalFilterProps {
  onChange: (users: string) => void;
  value: {users: string};
}

export const ActionItemsFilter = ({onChange, value}: GoalFilterProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string>(value.users || '');

  const findUsers = (_user: string) => {
    return users
      .filter((user) => user.id === _user)
      .map((user) => ({name: userName(user)}));
  };

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const formatUsers = useMemo(() => {
    return groupUsers(auth.user, users);
  }, [auth.user, users]);

  const [search, setSearch] = useState('');

  return (
    <div>
      <FlexRow className="gap-2">
        <Body2 weight="semibold">Assigned to</Body2>

        <DropdownCustom
          collapseOnClick={true}
          menu={(handleClose: () => void) => (
            <>
              <SearchBox
                placeholder="Search member"
                onChange={(event) => setSearch(event.target.value)}
                style={{height: '38px', marginBottom: '16px'}}
              />
              <div className="overflow-hidden">
                <div className="overflow-auto  max-h-[400px] ">
                  {formatUsers?.map((grp: any) => (
                    <div className="mb-3">
                      <GroupsSelect
                        groups={grp}
                        search={search}
                        theme="users"
                        handleChange={(data: {value: string}) => {
                          handleClose();
                          onChange(data?.value);
                          setSelectedUsers(data?.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <>
              <div>
                <Button
                  kind="secondary"
                  type="button"
                  onClick={handleOpen}
                  className="flex items-center justify-between"
                  style={{
                    minWidth: '125px',
                    height: '38px',
                    overflow: 'hidden',
                    padding: '6px 8px',
                  }}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    style={{width: '100%'}}>
                    <Body2 weight="bold">
                      {findUsers(selectedUsers)?.[0]?.name || 'All members'}
                    </Body2>
                    <FiChevronDown
                      style={{margin: '0px 5px 0px 0px'}}
                      aria-hidden="true"
                    />
                  </Flex>
                </Button>
              </div>
            </>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            width: '350px',
            left: '-50%',
          }}
        />
      </FlexRow>
    </div>
  );
};

interface ActionItemsSortByFilterProps {
  updateSortBy: (value: string) => void;
  isCustomFilter: boolean;
  sortBy: string;
  userId: string;
}
export const ActionItemsSortByFilter = ({
  updateSortBy,
  isCustomFilter,
  sortBy,
  userId,
}: ActionItemsSortByFilterProps) => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div>
          <DropdownItem
            onClick={() => {
              updateSortBy('dueDate');
              handleClose();
              updateActionItemsFilter(userId, {
                type: '',
              });
            }}>
            Due date
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              updateSortBy('createdAt');
              handleClose();
              updateActionItemsFilter(userId, {
                type: '',
              });
            }}>
            Date created
          </DropdownItem>{' '}
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <button onClick={handleOpen} style={{border: 0}}>
          <FlexRowSpaceBetween>
            <FilterIcon />

            <Body2
              weight="bold"
              className="whitespace-nowrap"
              kind={'purple300'}
              style={{marginLeft: '5px'}}>
              Sort by:{' '}
              {isCustomFilter
                ? 'Custom'
                : sortBy === 'dueDate'
                ? 'Due date'
                : 'Date created'}
            </Body2>
          </FlexRowSpaceBetween>
        </button>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        width: '160px',
        padding: '8px',
        right: 0,
      }}
    />
  );
};
