import {observer} from 'mobx-react-lite';
import {
  DelimitingWrapper,
  OuterWrapper,
  Wrapper,
  ButtonWrapper,
  FeedbackDiv,
  ItemLoaderWrapper,
  EmptyDiv,
  TagWrapper,
} from './my_checkins-style';
import {Headline3, Body1, Body2} from '@ui/atoms/typography';
import {useCallback} from 'react';
import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {MoreAboutWorkLife} from './components/work-life';
import {TextField} from '@ui/molecules/field/textfield';
import {ProgressOnObjectives} from './components/objectives-progress';
import {useYouCheckinsHook} from '@pages/dashboard/you/you-components/you-checkins/you-checkins.hooks';
import {UseCheckinGoals} from '@pages/dashboard/you/you-components/you-checkins/you-checkins-goals';
import {Controller} from 'react-hook-form';
import {QuestionIconWrapper} from '@ui/molecules/tooltip/tooltip';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {updateTooltipStatus} from '@utils/firebase-request';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useStoreContext} from '@store/store-context';
import {ItemLoader} from '@ui/organisms/item-loader';
import {MoodBox} from './components/mood-box';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {
  dateParamsToday,
  getDayOfWeekIndexFromString,
  getWeekOfYear,
} from '@utils/date';
import {useYouHook} from '@pages/dashboard/you/you-hook';
import {checkinName} from '@utils/checkin-name';
import {capitalize} from '@utils';
import {Priorities} from './components/priorities';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import CalendarDropdown from './calendar-dropdown';
import dayjs from 'dayjs';
import {LockIcon} from '@ui/atoms/icons';

interface IMyCheckins {}
export const parseName = (data: any) => {
  return data?.firstName && data?.lastName
    ? `${data?.firstName} ${data?.lastName}`
    : data?.email;
};

export const TooltipBadge = ({message}: {message: string}) => {
  return (
    <QuestionIconWrapper tooltip={true}>
      <AlertBadge />
      <span
        className="tooltip"
        style={{
          width: '300px',
          padding: '16px',
        }}>
        {message}
      </span>
    </QuestionIconWrapper>
  );
};
export const SecondaryTooltipBadge = ({tooltipId}: {tooltipId: string}) => {
  return (
    <PlainButton
      type="button"
      style={{marginLeft: '2px'}}
      onClick={() => updateTooltipStatus(tooltipId, {isDismissed: false})}>
      <AlertBadge />
    </PlainButton>
  );
};
// eslint-disable-next-line no-empty-pattern
export const MyCheckins = observer(({}: IMyCheckins) => {
  const {loadingCheckins} = useYouHook();

  const {
    handleSubmit,
    control,
    isSubmitting,
    submitForm,
    debounceUpdatePulse,
    debounceUpdatePriorities,
    updatePriorities,
    updatePulse,
    debounceUpdateQuestions,
    handleFormValueChange,
    watch,
    loadingPage,
    isReadOnly,
    setIsReadOnly,
  } = useYouCheckinsHook();

  const {loadingGoals} = UseCheckinGoals();
  const {isPerformanceActive} = useWorkspaceUseCase();

  const PastDueNotif = () => {
    return (
      <>
        <FlexRow>
          <TagWrapper>
            Submission closed
            <LockIcon />
          </TagWrapper>
        </FlexRow>
      </>
    );
  };

  const SubmittedNotif = () => {
    return (
      <>
        <FlexRow>
          <TagWrapper>
            Submitted {dayjs(workspaceCheckins?.submittedAt).format('MMMM DD')}
          </TagWrapper>
        </FlexRow>
      </>
    );
  };

  const {
    checkinsStore: {
      workspaceCheckinsGoals,
      workspaceCheckins,
      checkinsPulse,
      configuration,
    },
  } = useStoreContext();

  // 05. Manager Feedback
  const ManagerFeedback = useCallback(
    ({value}: {value: string}) => {
      return (
        <FeedbackDiv className="bg-white">
          <div className="titleDiv">
            <Headline3 kind="textDark">Manager feedback</Headline3>
          </div>
          <div className="contentDiv">
            {watch()?.feedback ? (
              <TextField
                multiple
                placeholder="Add a comment"
                value={value}
                readOnly
                disabled={
                  workspaceCheckins?.isReviewed ||
                  workspaceCheckins?.isSubmitted
                }
              />
            ) : (
              <Body1 kind="textBody"> No feedback given</Body1>
            )}
          </div>
        </FeedbackDiv>
      );
    },
    [workspaceCheckins?.isReviewed, workspaceCheckins?.isSubmitted, watch],
  );

  const noCheckinsData =
    workspaceCheckins?.responses?.questions === null &&
    workspaceCheckins?.responses?.pulse === null &&
    workspaceCheckins?.responses?.priorities === null &&
    (!workspaceCheckins?.responses?.goals ||
      workspaceCheckinsGoals?.length === 0);

  const EmptyResponse = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: cloudinary.emptyGoals,
      style: {height: '50%', width: '50%'},
    },
    subtitle: noCheckinsData
      ? `${capitalize(
          checkinName(),
        )}s active for this week, however relevant checkins sections are disabled`
      : '',
    title: noCheckinsData
      ? `${capitalize(checkinName())}s active`
      : `You currently have no  ${checkinName()} due.`,
  };
  const allowsLateSubmission = configuration?.allowLateSubmission;

  const isPastDue =
    workspaceCheckins?.weekOfYear !== getWeekOfYear() ||
    getDayOfWeekIndexFromString(dayjs().format('dddd')) >
      getDayOfWeekIndexFromString(workspaceCheckins?.dueDay);

  const isCheckinValid = Object.keys(workspaceCheckins).length > 0;

  const isCheckinOnTime =
    workspaceCheckins?.year === new Date().getFullYear() &&
    workspaceCheckins?.weekOfYear === getWeekOfYear() &&
    dateParamsToday().dayOfWeekIndex <=
      getDayOfWeekIndexFromString(workspaceCheckins?.dueDay);

  const canSubmit =
    (allowsLateSubmission && isCheckinValid) ||
    (!workspaceCheckins?.isReviewed && isCheckinValid && isCheckinOnTime);

  return (
    <>
      <FlexRowSpaceBetween className="border border-borderLight py-4 px-6 rounded-[10px] bg-white mb-6  ">
        <div className="gap-4 flex items-center flex-row sm:flex-col  sm:items-start sm:gap-2">
          <Body2 weight="semibold">Date Period</Body2>
          <CalendarDropdown />
        </div>

        {workspaceCheckins?.isSubmitted ? (
          <SubmittedNotif />
        ) : allowsLateSubmission ? (
          <></>
        ) : isPastDue ? (
          <PastDueNotif />
        ) : (
          <TagWrapper>
            <Body2 weight="semibold" kind="textBody">
              Due this {workspaceCheckins?.dueDay}
            </Body2>
          </TagWrapper>
        )}
      </FlexRowSpaceBetween>
      {!loadingPage && !loadingGoals && (
        <OuterWrapper onSubmit={handleSubmit(submitForm)}>
          <DelimitingWrapper>
            <Wrapper>
              {Object.keys(workspaceCheckins).length > 0 && (
                <>
                  {checkinsPulse !== null && (
                    <>
                      <Controller
                        control={control}
                        name="pulse"
                        render={({value}) => (
                          <MoodBox
                            value={value}
                            isSubmitted={!!checkinsPulse?.mood}
                            debounceUpdatePulse={debounceUpdatePulse}
                            updatePulse={updatePulse}
                            isReadOnly={isReadOnly}
                            handleFormValueChange={handleFormValueChange}
                          />
                        )}
                      />
                      <VerticalSpacer size="24px" />
                    </>
                  )}

                  {workspaceCheckins?.responses?.goals &&
                    isPerformanceActive &&
                    workspaceCheckinsGoals?.length > 0 && (
                      <>
                        <ProgressOnObjectives
                          loadingGoals={loadingGoals}
                          goals={workspaceCheckinsGoals}
                          isReadOnly={isReadOnly}
                        />
                        <VerticalSpacer size="24px" />
                      </>
                    )}

                  {workspaceCheckins?.responses?.priorities !== null &&
                    !loadingCheckins && (
                      <>
                        <Controller
                          control={control}
                          name="priorities"
                          render={({value}) => (
                            <Priorities
                              value={value}
                              isReadOnly={isReadOnly}
                              debounceUpdatePriorities={
                                debounceUpdatePriorities
                              }
                              updatePriorities={updatePriorities}
                              loadingCheckins={loadingCheckins}
                              handleFormValueChange={handleFormValueChange}
                            />
                          )}
                        />
                        <VerticalSpacer size="24px" />
                      </>
                    )}

                  {workspaceCheckins?.responses?.questions !== null && (
                    <Controller
                      control={control}
                      name="questions"
                      render={({value}) => (
                        <MoreAboutWorkLife
                          value={value}
                          isReadOnly={isReadOnly}
                          debounceUpdateQuestions={debounceUpdateQuestions}
                          handleFormValueChange={handleFormValueChange}
                        />
                      )}
                    />
                  )}
                  {workspaceCheckins?.isReviewed && (
                    <>
                      <VerticalSpacer size="24px" />
                      <Controller
                        control={control}
                        name="feedback"
                        render={({value}) => ManagerFeedback({value})}
                      />
                    </>
                  )}
                </>
              )}
              {(Object.keys(workspaceCheckins).length === 0 ||
                noCheckinsData) && (
                <EmptyDiv className="bg-white">
                  <EmptyPlaceholder {...EmptyResponse} />
                </EmptyDiv>
              )}
            </Wrapper>
          </DelimitingWrapper>

          {canSubmit && (
            <ButtonWrapper>
              {isReadOnly && (
                <Button
                  width="full"
                  type="button"
                  kind="secondary"
                  onClick={() => setIsReadOnly(false)}>
                  Edit {checkinName()}s
                </Button>
              )}
              {!isReadOnly && (
                <Button
                  width="full"
                  type="submit"
                  data-form-action={true}
                  isLoading={isSubmitting}
                  aria-busy={isSubmitting}>
                  {workspaceCheckins?.isSubmitted
                    ? 'Save changes'
                    : `Submit ${checkinName()}`}
                </Button>
              )}
            </ButtonWrapper>
          )}
        </OuterWrapper>
      )}
      {(loadingPage || loadingGoals) && (
        <ItemLoaderWrapper className="bg-white">
          <ItemLoader />
        </ItemLoaderWrapper>
      )}
    </>
  );
});
