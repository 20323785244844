import {PageSideLayout} from '../../../ui/layouts/page-size';
import {useStoreContext} from '../../../store/store-context';
import {observer} from 'mobx-react';
import {LockIcon} from '../../../ui/atoms/icons';
import {useMemo, useState, useEffect, useCallback} from 'react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import {AdminAnalyticsHook} from './reporting-pages/admin-analytics/admin-analytics.hooks';
import {PulseCheckinsAnalytics} from './reporting-pages/admin-analytics/analytics-pages/analytics-checkins';
import {useFreeTierHook} from '@hooks/free-tier';
import {Route, Routes, useNavigate} from 'react-router';
import {usePeriodHook} from '@hooks/period-hook';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {useSnapshotWidget} from './reporting-pages/reporting-overview/hooks/snapshot-widgets';
import {useIsRouteActive} from '@utils/route-href';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {cfv} from '../../../utils/framework';
import {AnalyticsFeedback} from './reporting-pages/admin-analytics/analytics-pages/analytics-feedback/analytics-feedback';
import {AnalyticsMeeting} from './reporting-pages/admin-analytics/analytics-pages/analytics-meeting/analytics-meeting';
import {AnalyticsStatus} from './reporting-pages/admin-analytics/analytics-pages/analytics-status/analytics-status';
import {AnalyticsQuestions} from './reporting-pages/admin-analytics/analytics-pages/analytics-questions';
import {checkinName} from '@utils/checkin-name';
import {useTags} from '@hooks/tags';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Headline2} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {AnalyticsActionItems} from './reporting-pages/admin-analytics/analytics-pages/analytics-action-items';
import {ReportingFilter} from './reporting-filter';
import {capitalize} from '@utils';

const REPORTING_ROUTES = {
  GOALS: `goals`,
  CHECKINS: 'checkins',
  OBJECTIVES: 'objectives',
  KPIs: 'kpis',
  FEEDBACK: `feedback`,
  MEETING: `meetings`,
  ACTION_ITEMS: `action-items`,
  QUESTIONS: `questions`,
  OVERVIEW: `overview`,
  PRESENTATIONS: `presentation`,
};

export const CompanyReporting = observer(
  ({
    tab,
  }: {
    showOverview?: boolean;
    tab?: 'overview' | 'status' | 'ownership';
  }) => {
    const {
      storeDashboardRoutes,
      authStore: {auth},
      analyticsStore: {
        goalsData,
        setFilterCompletion,
        setFilterStatus,
        setFilterState,
        goalsDate,
        modifyGoalsDate,
        updateSelectedTags,
        updateSearchValue,
        setGoalBreakdownBy,
      },
    } = useStoreContext();

    dayjs.extend(customParseFormat);

    const [additionalFilters, setAdditionalFilters] = useState<string[]>([]);

    const {isPerformanceActive, isEngagementActive} = useWorkspaceUseCase();

    const {getOptions: overviewWidgetOptions} = useSnapshotWidget();

    const isRouteActive = useIsRouteActive();

    const {
      activateCompanyReportingTab,
      companyReportingTabs,
      activateStatusTab,
    } = storeDashboardRoutes;

    AdminAnalyticsHook();

    const isGoalsActive = useMemo(() => isRouteActive(REPORTING_ROUTES.GOALS), [
      isRouteActive,
    ]);

    const isFeedbackActive = useMemo(
      () => isRouteActive(REPORTING_ROUTES.FEEDBACK),
      [isRouteActive],
    );

    const isActionItemsActive = useMemo(
      () => isRouteActive(REPORTING_ROUTES.ACTION_ITEMS),
      [isRouteActive],
    );

    const isCheckinsActive = useMemo(
      () => isRouteActive(REPORTING_ROUTES.CHECKINS),
      [isRouteActive],
    );

    const isObjectivesActive = useMemo(
      () => isRouteActive(REPORTING_ROUTES.OBJECTIVES),
      [isRouteActive],
    );

    const isQuestionsActive = useMemo(
      () => isRouteActive(REPORTING_ROUTES.QUESTIONS),
      [isRouteActive],
    );

    const isKPIsActive = useMemo(() => isRouteActive(REPORTING_ROUTES.KPIs), [
      isRouteActive,
    ]);

    const {
      analyticsStore: {updateDateValue, setSearchable, setAnalyticsRange},
      storeDashboardRoutes: {
        activateDashboardTab,
        activateAnalyticsDashboardTab,
        GoalViewTabs,
        statusTabs,
      },
      feedbackStore: {setDateRange: updateFeedbackRange},
    } = useStoreContext();

    // Free tier
    const {
      isAccountFreeTier,
      handleShowFreeTierModal: setFeedbackFreeTier,
    } = useFreeTierHook();

    // Free tier
    const {
      handleShowFreeTierModal: setMeetingFreeTierModal,
    } = useFreeTierHook();

    useEffect(() => {
      activateDashboardTab('reporting');
      if (isGoalsActive && !companyReportingTabs.status) {
        activateCompanyReportingTab('status');
      }
      if (isFeedbackActive && !companyReportingTabs.feedback) {
        activateCompanyReportingTab('feedback');
      }
    }, [
      activateDashboardTab,
      tab,
      activateCompanyReportingTab,
      isFeedbackActive,
      isGoalsActive,
      companyReportingTabs.status,
      companyReportingTabs.feedback,
    ]);

    const {currentMonthRange} = usePeriodHook();

    const enableOverview = overviewWidgetOptions.length;

    const {isFeedBackEnabled} = useWorkspaceUseCase();

    const navigate = useNavigate();

    useEffect(() => {
      if (
        !enableOverview &&
        window.location.pathname.includes(REPORTING_ROUTES.OVERVIEW)
      ) {
        if (isFeedBackEnabled && isEngagementActive)
          return navigate(REPORTING_ROUTES.FEEDBACK);

        navigate(REPORTING_ROUTES.MEETING);
      }
    }, [enableOverview, isFeedBackEnabled, navigate, isEngagementActive]);

    const [selectedGroups, setSelectedGroups] = useState<
      {label: string; value: string}[]
    >([]);

    const [selectedTeams, setSelectedTeams] = useState<
      {label: string; value: string}[]
    >([]);

    const [selectedMembers, setSelectedMembers] = useState<
      {label: string; value: string}[]
    >([]);

    const [selectedPulse, setSelectedPulse] = useState<string[]>([]);

    const [selectedGoalStatusToggles, setGoalStatusToggles] = useState<
      string[]
    >(['past_due', 'on_track', 'behind', 'at_risk', 'no_status']);

    const [feedbackFilter, setFeedbackFilter] = useState({
      sentiment: '',
      count: '',
      values: [] as string[],
    });

    const {tags} = useTags(`goal_tags`);

    const [meetingFilter, setMeetingFilter] = useState({
      meetingType: '',
      count: '',
    });

    const [breakdownBy, setBreakdownBy] = useState('');

    const resetFilter = useCallback(() => {
      setMeetingFilter({
        meetingType: '',
        count: '',
      });
      setFeedbackFilter({
        sentiment: '',
        count: '',
        values: [],
      });
      setSelectedPulse([]);
      setSelectedGroups([]);
      setSelectedMembers([]);
      updateSearchValue('');
      setSearchable('members', '');
      setSearchable('managers', '');
      setSearchable('department', '');
      setSearchable('goalType', '');
      setSelectedTeams([]);
      setAdditionalFilters([]);

      setBreakdownBy('');
    }, [setSearchable, updateSearchValue]);

    const {isActionItemEnabled} = useWorkspaceUseCase();

    const AnalyticsNavigations = useMemo(() => {
      const nav = [];

      if (isPerformanceActive) {
        nav.push({
          active: isObjectivesActive,
          href: REPORTING_ROUTES.OBJECTIVES,
          id: '1',
          onClick: () => {
            activateAnalyticsDashboardTab('objectives');
            activateStatusTab('goal');

            resetFilter();
          },
          title: capitalize(cfv(undefined, true).g_oals),
        });
        if (auth.user.workspace.config.allowKpi) {
          nav.push({
            active: isKPIsActive,
            href: REPORTING_ROUTES.KPIs,
            id: '2',
            onClick: () => {
              activateStatusTab('Kpi');
              setAnalyticsRange('days');
              resetFilter();
            },
            title: 'KPIs',
          });
        }
      }

      if (isEngagementActive) {
        nav.push({
          title: `Pulse & ${capitalize(checkinName())}s`,
          href: REPORTING_ROUTES.CHECKINS,
          onClick: () => {
            activateCompanyReportingTab('meeting');
            resetFilter();
            updateDateValue('');
          },
          active: isCheckinsActive,
          id: '1',
          disable: false,
          subNavigation: [],
          variant: '',
          iconSpacing: 'flex-start',
          icon: <></>,
        });

        if (isActionItemEnabled) {
          nav.push({
            title: 'Action items',
            href: REPORTING_ROUTES.ACTION_ITEMS,
            onClick: () => {
              if (isAccountFreeTier) {
                return setMeetingFreeTierModal(true);
              }
              activateCompanyReportingTab('action-items');
              resetFilter();
              updateDateValue('');
            },
            active: isActionItemsActive,
            id: '1',
            disable: false,
            subNavigation: [],
            variant: '',
            iconSpacing: 'flex-start',
            icon: isAccountFreeTier ? <LockIcon /> : <></>,
          });
        }
      }
      if (isEngagementActive) {
        nav.push({
          title: 'Questions',
          href: REPORTING_ROUTES.QUESTIONS,
          onClick: () => {
            //  activateCompanyReportingTab('meeting');
            resetFilter();
            updateDateValue('');
          },
          active: isQuestionsActive,
          id: '1',
          disable: false,
          subNavigation: [],
          variant: '',
          iconSpacing: 'flex-start',
          icon: <></>,
        });
      }

      if (
        auth.user.workspace.settings.feedback === 'enabled' &&
        isEngagementActive
      ) {
        nav.push({
          title: 'Feedback',
          href: REPORTING_ROUTES.FEEDBACK,
          onClick: () => {
            if (isAccountFreeTier) {
              return setFeedbackFreeTier(true);
            }
            activateCompanyReportingTab('feedback');
            resetFilter();
            updateDateValue('');
            updateFeedbackRange({
              starts: currentMonthRange().starts,
              ends: currentMonthRange().ends,
              period: currentMonthRange().period,
            });
          },
          active: isFeedbackActive,
          id: '1',
          disable: false,
          subNavigation: [],
          variant: '',
          iconSpacing: 'flex-start',
          icon: isAccountFreeTier ? <LockIcon /> : <></>,
        });
      }

      return nav;
    }, [
      activateAnalyticsDashboardTab,
      activateCompanyReportingTab,
      activateStatusTab,
      auth.user.workspace.config.allowKpi,
      auth.user.workspace.settings.feedback,
      currentMonthRange,
      isAccountFreeTier,
      isActionItemEnabled,
      isActionItemsActive,
      isCheckinsActive,
      isEngagementActive,
      isFeedbackActive,
      isKPIsActive,
      isObjectivesActive,
      isPerformanceActive,
      isQuestionsActive,
      resetFilter,
      setAnalyticsRange,
      setFeedbackFreeTier,
      setMeetingFreeTierModal,
      updateDateValue,
      updateFeedbackRange,
    ]);

    useEffect(() => {
      if (isKPIsActive && !statusTabs.Kpi) {
        activateStatusTab('Kpi');
        setAnalyticsRange('days');
      }
    }, [activateStatusTab, isKPIsActive, setAnalyticsRange, statusTabs.Kpi]);

    const activeFilter = () => {
      if (isCheckinsActive) return 'checkin';
      if (isFeedbackActive) return 'feedback';
      if (isActionItemsActive) return 'actionItems';
      if (isObjectivesActive) return 'objectives';
      if (isKPIsActive) return 'kpis';
      if (isQuestionsActive) return 'questions';

      return 'checkin';
    };

    const [showObjectiveStatus, setShowObjectiveStatus] = useState(true);

    const canUpdateGoals = ['objectives', 'kpis'].includes(activeFilter());

    return (
      <div style={{paddingBottom: 100}}>
        <>
          <FlexRowSpaceBetween className="mb-6">
            <div className="">
              <Headline2>Reports</Headline2>
              <div className="w-[80%] mt-1">
                <Body1 kind="textBody">
                  View and download insights from team and company-wide
                  activities across your workspace.
                </Body1>
              </div>
            </div>
          </FlexRowSpaceBetween>

          <TabNavigation navigations={AnalyticsNavigations} />
          <VerticalSpacer size="24px" />
        </>

        <PageSideLayout
          variant={GoalViewTabs.tree ? 'tree' : 'reporting'}
          emptyTreeGoalState={goalsData?.goals.length === 0}
          content={
            <>
              <Routes>
                <Route
                  path="/feedback"
                  element={
                    <AnalyticsFeedback
                      selectedMembers={selectedMembers}
                      selectedTeams={selectedTeams}
                      selectedGroups={selectedGroups}
                      feedbackFilter={feedbackFilter}
                      breakdownBy={breakdownBy}
                    />
                  }
                />
                <Route
                  path="/action-items"
                  element={
                    <AnalyticsActionItems
                      selectedMembers={selectedMembers}
                      selectedTeams={selectedTeams}
                      selectedGroups={selectedGroups}
                      // feedbackFilter={feedbackFilter}
                      breakdownBy={breakdownBy}
                    />
                  }
                />
                <Route
                  path={'/' + REPORTING_ROUTES.OBJECTIVES}
                  element={
                    <AnalyticsStatus
                      showObjectiveStatus={showObjectiveStatus}
                    />
                  }
                />
                <Route
                  path={'/' + REPORTING_ROUTES.KPIs}
                  element={<AnalyticsStatus />}
                />

                <Route
                  path={'/' + REPORTING_ROUTES.QUESTIONS}
                  element={
                    <AnalyticsQuestions
                      selectedMembers={selectedMembers}
                      selectedTeams={selectedTeams}
                      selectedGroups={selectedGroups}
                    />
                  }
                />

                <Route
                  path={REPORTING_ROUTES.MEETING}
                  element={
                    <AnalyticsMeeting
                      breakdownBy={breakdownBy}
                      selectedMembers={selectedMembers}
                      selectedTeams={selectedTeams}
                      selectedGroups={selectedGroups}
                      meetingFilter={meetingFilter}
                    />
                  }
                />

                <Route
                  path="/checkins"
                  element={
                    <PulseCheckinsAnalytics
                      breakdownBy={breakdownBy}
                      selectedMembers={selectedMembers}
                      pulseFilters={selectedPulse}
                      selectedTeams={selectedTeams}
                      selectedGroups={selectedGroups}
                    />
                  }
                />
              </Routes>
            </>
          }
          navigation={
            <div className="">
              <ReportingFilter
                type={activeFilter()}
                dateRange={goalsDate}
                additionalFilters={additionalFilters}
                setAdditionalFilters={setAdditionalFilters}
                setDateRange={(date: any) =>
                  modifyGoalsDate(date.starts, date.ends, date.period)
                }
                selectedGroups={selectedGroups}
                onChangeTeams={(data) => {
                  setSelectedTeams(data);
                  if (canUpdateGoals) {
                    setSearchable(
                      'managers',
                      data.map((manager) => manager.value).join(','),
                    );
                  }
                }}
                selectedTeams={selectedTeams}
                onChangeMembers={(data) => {
                  setSelectedMembers(data);
                  if (canUpdateGoals) {
                    setSearchable(
                      'members',
                      data.map((member) => member.value).join(','),
                    );
                  }
                }}
                selectedMembers={selectedMembers}
                onChangeGroups={(data) => {
                  setSelectedGroups(data);
                  if (canUpdateGoals) {
                    setSearchable(
                      'department',
                      data.map((department) => department.value).join(','),
                    );
                  }
                }}
                breakdownBy={breakdownBy}
                disableAdditionalFilter={['kpis', 'questions'].includes(
                  activeFilter(),
                )}
                selectedPulse={selectedPulse}
                onChangePulse={(data) => setSelectedPulse(data)}
                onChangeGoalProgress={(data) => {
                  setFilterCompletion('goals', data.join(','));
                }}
                onChangeGoalType={(value, groups) => {
                  setSearchable('goalType', value);
                  setSearchable('department', groups?.join(',') || '');
                }}
                onChangeGoalState={(value) => {
                  setFilterState('goals', value || 'active,past_due');
                }}
                onChangeStatus={(data) => {
                  setGoalStatusToggles(data);
                  setFilterStatus('goals', data.join(','));
                }}
                goalStatus={selectedGoalStatusToggles}
                onChangeGoalTags={(value) => updateSelectedTags(value)}
                feedbackFilter={feedbackFilter}
                toggleObjectiveStatus={setShowObjectiveStatus}
                showObjectiveStatus={showObjectiveStatus}
                onChangeFeedbackFilter={(data) => setFeedbackFilter(data)}
                kpiTags={tags
                  .filter((tag) => !!tag.tag?.name)
                  .map((tag) => ({
                    value: tag.tag?.name,
                    label: tag.tag?.name,
                  }))}
                handleMeetingFilter={setMeetingFilter}
                setBreakdownBy={(value) => {
                  setBreakdownBy(value);

                  if (activeFilter() === 'objectives') {
                    setGoalBreakdownBy(value);
                  }
                }}
              />
            </div>
          }
        />
      </div>
    );
  },
);
