import {useStoreContext} from '@store/store-context';
import {PulseCard} from '@ui/organisms/pulse';
import React, {useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {useHomePulseHook} from './pulse-hook';
import {useQuery} from 'react-query';
import {usePeriodHook} from '@hooks/period-hook';
import {AnalyticsTodayDate, daysLeftToNextCheckin} from '@utils/date';
import {observer} from 'mobx-react';
import {checkinName} from '@utils/checkin-name';

export const HomePulseWidget = observer(() => {
  const {
    authStore: {auth},
    checkinsStore: {configuration},
  } = useStoreContext();

  const [activeTab, setActiveTab] = useState<'you' | 'team' | 'company'>('you');

  const {currentMonthRange, isPeriodEnabled} = usePeriodHook();

  const getPeriod = useMemo(
    () =>
      isPeriodEnabled
        ? {
            starts: currentMonthRange().starts,
            ends: currentMonthRange().ends,
            period: currentMonthRange().period,
          }
        : {
            starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
            ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
          },
    [isPeriodEnabled, currentMonthRange],
  );

  const computeFilters = useMemo(() => {
    return {
      startDate: getPeriod.starts,
      endDate: getPeriod.ends,
      manager: activeTab === 'team' ? auth.user.id : undefined,
      member: activeTab === 'you' ? auth.user.id : undefined,
    };
  }, [activeTab, auth.user.id, getPeriod]);

  const navigate = useNavigate();

  const {
    initFilter,
    initWorkspaceCheckins,
    isSubmitting,
    updatePulse,
    getPulseReport,
  } = useHomePulseHook();

  const {isLoading, data: workspaceData, refetch} = useQuery(
    ['initPulse', initFilter],
    () => initWorkspaceCheckins(initFilter),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {isLoading: isLoadingReport, data: reportData} = useQuery(
    ['reporting-pulse', computeFilters],
    () => getPulseReport(computeFilters),
    {
      refetchOnWindowFocus: false,
    },
  );

  const checkinEnabled =
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const pulseFrequency = configuration?.generalFrequency?.frequency || 'weekly';

  return (
    <div className="mb-6">
      <PulseCard
        removeFooter={!checkinEnabled}
        viewFooterText={`${checkinName()}s`}
        dueDay={daysLeftToNextCheckin(pulseFrequency as any)}
        isLoading={activeTab === 'you' ? isLoading : isLoadingReport}
        showSummary={activeTab !== 'you'}
        summary={reportData}
        isSubmitting={isSubmitting}
        activeTab={activeTab}
        onSubmit={(data) =>
          updatePulse({
            pulse: data,
            pulseId: (workspaceData as any)?.id || '',
          }).then(() => {
            refetch();
          })
        }
        isPulseSubmitted={!!(workspaceData as any)?.mood || !workspaceData}
        handleContinue={() => navigate('/checkins')}
        showNav={auth.user.role === 'admin' || auth.user.isReviewer}
        showCompany={auth.user.role === 'admin'}
        showTeam={auth.user.isReviewer}
        updateActiveTab={(value) => setActiveTab(value)}
      />
    </div>
  );
});
