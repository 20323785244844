import {FeaturesLayout, BodyLayout} from '../admin-workspace-styled';
import {Headline2, Headline3, Body1} from '@ui/atoms/typography';
import {
  ContentBox,
  BreadcrumbWrapper,
  ResponsiveFlexRowBetween,
  SectionCardBody,
} from '../admin-workspace-styled';
import {FlexRow, FlexRowEnd} from '@ui/style/styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useBreakpoints} from '@utils/use-breakpoints';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {Button} from '@ui/atoms/button';
import {MultiField} from '@ui/molecules/field/multifield';
import {useStoreContext} from '@store/store-context';
import {Dropdown} from '@ui/molecules/dropdown';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {SetupWorkspaceRequestImpl} from '@pages/admin/setup-workspace/setup-workspace-request';
import {SetupWorkspaceController} from '@pages/admin/setup-workspace/setup-workspace-controller';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {useState} from 'react';
import {useIsRouteActive} from '@utils/route-href';
import {useNavigate} from 'react-router';
import {SectionCard} from '@ui/layouts/section-card';
import {observer} from 'mobx-react';
import {setFirebaseData} from '@utils/firebase-handler';

export const AdminValues = observer(() => {
  const [view, setView] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [errors, setErrors] = useState<
    Array<{
      key?: string;
      error?: string;
      val?: {text?: string; completed?: false};
    }>
  >([]);

  const {
    authStore: {auth},
  } = useStoreContext();

  const companyValues = auth?.user?.workspace?.settings?.companyValues?.values;

  const navigate = useNavigate();

  const formatCompanyValues =
    companyValues.length > 0
      ? companyValues?.map((value: string) => {
          return {
            text: value,
            completed: true,
          };
        })
      : [{text: ''}];

  const [values, setValues] = useState<
    Array<{text?: string; completed?: boolean}>
  >(formatCompanyValues);

  const handleAddCompanyValues = async () => {
    const duplicateTextValues = errors.map((error, index, errors) =>
      errors.findIndex(
        (t) =>
          t.val?.text?.trim().toLowerCase() ===
          error.val?.text?.trim().toLowerCase(),
      ) !== index
        ? {
            ...error,
            error: 'Error: Duplicate value found',
          }
        : error,
    );

    if (duplicateTextValues.some((error) => error.error)) {
      setErrors(duplicateTextValues);
      return;
    }

    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);
    const getValues = values.map((value) => value.text);
    setLoading(true);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,

          companyValues: {
            status: 'enabled',
            values: getValues,
          },
        },
      },
      auth?.tokens?.access?.token,
    );
    setValues((prev) =>
      prev.map((value) => {
        return {
          ...value,
          completed: true,
        };
      }),
    );
    response &&
      activateNotification({
        title: `Company valued updated`,
        content: `Settings updated`,
        kind: 'success',
      });
    setLoading(false);
    setIsEditing(false);

    response && setView(false);

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const allFieldsUpdated = values.every((value) => value.text);

  const ValuesBreadCrumb = [
    {
      href: '#',
      id: '1',
      onClick: () => {
        navigate(-1);
        setView(false);
      },
      title: 'Values',
    },
    {
      href: '',
      id: '2',
      isCurrent: true,
      onClick: function noRefCheck() {},
      title: 'Settings',
    },
  ];
  const {isXs} = useBreakpoints();
  const isRouteActive = useIsRouteActive();

  if (!view && !isRouteActive('value-settings')) {
    return (
      <FeaturesLayout>
        <BodyLayout>
          <Headline2>Workspace values</Headline2>
          <VerticalSpacer size="20px" />
          <ContentBox style={{marginTop: '6px'}}>
            <div className="flex-sm ">
              <div className="column">
                <Headline3 kind="textDark">Values</Headline3>
              </div>
              <Button
                style={{padding: '8px 16px'}}
                kind="secondary"
                onClick={() => {
                  setView(true);
                  navigate('value-settings');
                }}>
                Configure
              </Button>
            </div>
          </ContentBox>
        </BodyLayout>
      </FeaturesLayout>
    );
  }

  return (
    <>
      <BreadcrumbWrapper>
        <SecondaryBreadcrumb
          navigation={ValuesBreadCrumb}
          wrapperStyles={{marginBottom: 0}}
        />
      </BreadcrumbWrapper>
      <FeaturesLayout>
        <BodyLayout>
          <SectionCard
            title=""
            headerPadding={isXs ? 'small' : undefined}
            contentStyling={{
              padding: isXs ? '12px' : '24px 24px 10px 24px',
            }}
            CustomHeaderTitle={
              <ResponsiveFlexRowBetween style={{width: '100%'}}>
                <Body1 kind="textDark" weight="semibold" className="title">
                  Configure value metrics (max.15){' '}
                </Body1>
                {isEditing && (
                  <FlexRowEnd style={{width: '100%'}}>
                    <FlexRow>
                      <Button
                        style={{padding: '8px 16px'}}
                        disabled={!allFieldsUpdated}
                        isLoading={isLoading}
                        onClick={handleAddCompanyValues}>
                        Save
                      </Button>
                      <Button
                        kind="secondary"
                        style={{padding: '8px 16px'}}
                        onClick={() => {
                          setView(false);
                          setValues(formatCompanyValues);
                        }}>
                        Cancel
                      </Button>
                    </FlexRow>
                  </FlexRowEnd>
                )}
              </ResponsiveFlexRowBetween>
            }>
            <SectionCardBody>
              <MultiField
                label=""
                id=""
                checkedIndexes={[0]}
                value={values}
                margin
                fontWeight={400}
                showCheckbox
                colorString="textMedium"
                addFieldPlaceholder="Add new value"
                placeholder="Add value here"
                errors={errors}
                variant="youcheckins"
                returnValueKey
                maxFields={15}
                updateValue={(value) => {
                  if (value) {
                    setValues(value.map((val: any) => val.val));
                    setErrors(
                      value.map((val) => {
                        return {...val, error: undefined};
                      }),
                    );
                  }
                  setIsEditing(true);
                }}
                dropdownComponent={(
                  onHandleRemove: () => void,
                  fieldId: string,
                  indexOfFieldId: number,
                ) =>
                  values.length < 2 && !values[0].text ? (
                    <></>
                  ) : (
                    <Dropdown
                      customIcon={<HorizontalDots />}
                      dropdownItemStyle={{whiteSpace: 'nowrap'}}
                      menu={[
                        {
                          content: `Delete`,
                          id: 'deleteId',
                        },
                      ]}
                      onClick={(option: any) => {
                        onHandleRemove();
                      }}
                      specificIndexDropdownItemStyles={[
                        {index: 1, style: {color: '#D52A2A'}},
                      ]}
                    />
                  )
                }
              />
            </SectionCardBody>
          </SectionCard>
        </BodyLayout>
      </FeaturesLayout>
    </>
  );
});
