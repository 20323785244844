import {useSections} from '@hooks/action-items-sections';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useStoreContext} from '@store/store-context';
import {getTaskStats, getUserActionItems} from '@utils/action-items';
import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {useCallback, useMemo} from 'react';

interface Task {
  completed: boolean;
  id: string;
  meta: {} | null;
  text: string;
  source: string;
  completedAt?: string;
  sourceId: string;
  section?: string;
  updatedBy: string;
  createdBy: string;
  order: number;
  dueDate?: string;
  createdAt: string;
  user: any;
  assignee: any | null;
}

type SummaryKey =
  | 'assigned'
  | 'overdue'
  | 'completed'
  | 'total'
  | 'active'
  | 'undated'
  | 'undatedPercent'
  | 'activePercent'
  | 'completedPercent'
  | 'overduePercent'
  | 'completionRate';

type Summary = {
  [K in SummaryKey]: number;
};

type ComparisonResult<T> = {
  [K in keyof T]: {
    current: T[K];
    previous: T[K];
    change: number;
  };
};

type DateRange = {
  startDate: string;
  endDate: string;
};

export const useInsightActionItemHook = (filters: {
  members: string[];
  sections: string[];
  date: DateRange;
  comparisonDate?: DateRange | null;
}) => {
  const {data} = useFirebaseFetch('action_items');
  const {computeSections} = useSections();

  const {
    usersStore: {users},
  } = useStoreContext();

  const formatUsers = useMemo(() => {
    const {members} = filters;
    if (members.length) {
      return users.filter((user) => members.includes(user.id));
    }
    return users;
  }, [filters, users]);

  const computeActionItems = useCallback(() => {
    const convertAndFilterTasks = (tasks: any) => {
      if (!tasks) return [];
      if (!Array.isArray(tasks)) {
        return Object.entries(tasks).map(([firebaseId, task]: any) => ({
          ...task,
          firebaseId,
        }));
      }
      return tasks;
    };

    return convertAndFilterTasks(data)
      .map((task) => ({
        ...task,
        key: task.id || task._id || task.key || '',
      }))
      .sort((a, b) => a.order - b.order);
  }, [data]);

  const filteredUsers = formatUsers.map((user) => user.id);

  const filterTasksByDateRange = useCallback(
    (
      tasks: Task[],
      dateRange: DateRange,
      filterField: 'createdAt' | 'completedAt',
    ) => {
      const startDate = dayjs(dateRange.startDate);
      const endDate = dayjs(dateRange.endDate);
      return tasks.filter((task) => {
        const relevantDate = dayjs(task[filterField]);

        return (
          relevantDate.isAfter(startDate) &&
          relevantDate.isBefore(endDate.add(1, 'day'))
        );
      });
    },
    [],
  );

  const filterTasksBySections = useCallback(
    (tasks: any[], sections: string[]) => {
      const availableSections = computeSections;
      const sectionSlugs = sections
        .map(
          (section) => availableSections.find((s) => s.name === section)?.slug,
        )
        .filter(Boolean);

      return tasks.filter(
        (task) =>
          sectionSlugs.includes(task.section) ||
          (sectionSlugs.includes('inbox') && !task.section),
      );
    },
    [computeSections],
  );

  const tasks = useMemo(() => {
    let allTasks = computeActionItems()?.length
      ? computeActionItems().filter((task) =>
          filteredUsers.some((user) =>
            [task?.createdBy, task?.user, task?.assignee].includes(user),
          ),
        )
      : [{text: '', completed: false}];

    if (filters.sections.length) {
      allTasks = filterTasksBySections(allTasks, filters.sections);
    }

    return allTasks;
  }, [
    computeActionItems,
    filteredUsers,
    filterTasksBySections,
    filters.sections,
  ]);

  const calculateSummary = useCallback(
    (allTasks: any[], dateRange: DateRange | undefined | null) => {
      if (!dateRange) {
        return {
          assigned: 0,
          overdue: 0,
          completed: 0,
          total: 0,
          active: 0,
          undated: 0,
          undatedPercent: 0,
          activePercent: 0,
          completedPercent: 0,
          overduePercent: 0,
          completionRate: 0,
        };
      }

      const activeTasks = filterTasksByDateRange(
        allTasks.filter((task) => !task.completed),
        dateRange,
        'createdAt',
      );

      const completedTasks = filterTasksByDateRange(
        allTasks.filter((task) => task.completed),
        dateRange,
        'completedAt',
      );

      const total = activeTasks.length + completedTasks.length;

      const assigned = activeTasks.filter((task) => !!task?.assignee).length;

      const overdue = activeTasks.filter((task) =>
        dayjs().isAfter(dayjs(task?.dueDate)),
      ).length;

      const undated = activeTasks.filter((task) => !task?.dueDate).length;

      const completedOnDueDate = completedTasks.filter(
        (task) =>
          dayjs(task.completedAt).isSame(dayjs(task.dueDate), 'day') ||
          dayjs(task.completedAt).isBefore(dayjs(task.dueDate), 'day'),
      ).length;

      return {
        assigned,
        overdue,
        completed: completedTasks.length,
        total,
        active: activeTasks.length - overdue,
        undated,
        undatedPercent: total > 0 ? Math.round((undated / total) * 100) : 0,
        activePercent:
          total > 0 ? Math.round((activeTasks.length / total) * 100) : 0,
        completedPercent:
          total > 0 ? Math.round((completedTasks.length / total) * 100) : 0,
        overduePercent: total > 0 ? Math.round((overdue / total) * 100) : 0,
        completionRate:
          completedTasks.length > 0
            ? Math.round((completedOnDueDate / completedTasks.length) * 100)
            : 0,
      };
    },
    [filterTasksByDateRange],
  );

  const summary = useMemo(() => calculateSummary(tasks, filters.date), [
    tasks,
    filters.date,
    calculateSummary,
  ]);
  const comparisonSummary = useMemo(
    () => calculateSummary(tasks, filters.comparisonDate),
    [tasks, filters.comparisonDate, calculateSummary],
  );

  const summaryWithComparison = useMemo(() => {
    const calculateChange = (current: number, previous: number): number => {
      if (!filters.comparisonDate) return 0;

      if (previous === 0 || isNaN(previous)) {
        return current === 0 ? 0 : 100;
      }
      return Math.round(((current - previous) / previous) * 100);
    };

    return Object.keys(summary).reduce<ComparisonResult<Summary>>(
      (acc, key) => {
        const currentValue = summary[key as keyof Summary];

        const previousValue = !filters.comparisonDate
          ? 0
          : comparisonSummary
          ? comparisonSummary[key as keyof Summary]
          : 0;

        acc[key as keyof Summary] = {
          current: currentValue,
          previous: previousValue,
          change: calculateChange(currentValue, previousValue),
        };
        return acc;
      },
      {} as ComparisonResult<Summary>,
    );
  }, [summary, filters.comparisonDate, comparisonSummary]);

  const useFilteredData = (
    formatUsers: any[],
    tasks: Task[],
    currentDateRange: {startDate: string; endDate: string},
    comparisonDateRange?: {startDate: string; endDate: string} | null,
  ) => {
    const filteredData = useMemo(() => {
      const filterTasksByDateRange = (
        tasks: Task[],
        startDate: string,
        endDate: string,
      ) => {
        return tasks.filter((task) => {
          const taskDate = task?.completedAt;

          return taskDate
            ? dayjs(taskDate).isBetween(startDate, endDate, null, '[]') ||
                dayjs(taskDate).isToday()
            : false;
        });
      };

      const currentTasks = filterTasksByDateRange(
        tasks,
        currentDateRange.startDate,
        currentDateRange.endDate,
      );

      const comparisonTasks = comparisonDateRange
        ? filterTasksByDateRange(
            tasks,
            comparisonDateRange.startDate,
            comparisonDateRange.endDate,
          )
        : [];

      return formatUsers.map((user) => {
        const currentUserActionItems = getUserActionItems(
          user.id,
          currentTasks,
        );

        const comparisonUserActionItems = getUserActionItems(
          user.id,
          comparisonTasks,
        );

        const currentStats = getTaskStats(currentUserActionItems, user.id);

        const comparisonStats = getTaskStats(
          comparisonUserActionItems,
          user.id,
        );

        return {
          name: userName(user),
          avatar: user?.avatar?.url || '',
          current: currentStats,
          comparison: comparisonStats,
        };
      });
    }, [formatUsers, tasks, currentDateRange, comparisonDateRange]);

    return filteredData;
  };

  const filteredData = useFilteredData(
    formatUsers,
    tasks,
    filters.date,
    filters.comparisonDate,
  );

  return {
    summary: summaryWithComparison,
    filteredData,
  };
};
