import React, {useRef, useEffect, memo, useCallback} from 'react';
import * as d3 from 'd3';

interface DataPoint {
  name: string;
  value: number;
  count: number;
  total: number;
  comparedValue?: number;
  comparedCount?: number;
  comparedTotal?: number;
}

interface ResponsiveBarChartProps {
  data: DataPoint[];
  hasComparison?: boolean;
}

export const ResponsiveBarChart: React.FC<ResponsiveBarChartProps> = memo(
  ({data, hasComparison = false}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    const drawChart = useCallback(() => {
      if (!data || data.length === 0 || !svgRef.current) return;

      const svg = d3.select(svgRef.current);
      svg.selectAll('*').remove(); // Clear previous render

      const margin = {top: 20, right: 80, bottom: 20, left: 150};
      const width = svgRef.current.getBoundingClientRect().width;
      const height = data.length * 60; // Adjust height based on number of data points

      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      const x = d3.scaleLinear().domain([0, 100]).range([0, chartWidth]);

      const y = d3
        .scaleBand()
        .domain(data.map((d) => d.name))
        .range([0, chartHeight])
        .padding(0.3);

      const g = svg
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // X-axis
      g.append('g')
        .attr('transform', `translate(0,${chartHeight + 40})`) // Add margin between x and y axis
        .call(
          d3
            .axisBottom(x)
            .tickSize(-11)
            .tickFormat((d) => `${d}%`),
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').attr('stroke', '#5F5F8C'))
        .call(
          (g) =>
            g
              .selectAll('.tick text')
              .attr('y', 10)
              .attr('x', 5)
              .attr('fill', '#5F5F8C')
              .style('font-size', '12px')
              .style('font-family', 'Graphik'), // Set font family to Graphik
        );

      // Y-axis
      g.append('g')
        .attr('transform', `translate(-10,-10)`)
        .call(
          d3
            .axisLeft(y)
            .tickSize(0)
            .tickFormat((d) => (d.length > 15 ? d.slice(0, 15) + '...' : d)),
        )
        .call((g) => g.select('.domain').remove())
        .call(
          (g) =>
            g
              .selectAll('.tick text')
              .attr('fill', 'black')
              .style('font-size', '14px')
              .style('font-family', 'Graphik'), // Set font family to Graphik
        );

      // Vertical line at 0%
      g.append('line')
        .attr('x1', x(0))
        .attr('x2', x(0))
        .attr('y1', 0)
        .attr('y2', chartHeight)
        .attr('stroke', '#E1E1EA')
        .attr('stroke-width', 1);

      // Bars
      const bars = g
        .selectAll('.bar-group')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'bar-group')
        .attr('transform', (d) => `translate(0,${y(d.name)})`);

      // Current period bars
      bars
        .append('rect')
        .attr('height', y.bandwidth() / 2 - 2)
        .attr('x', x(0))
        .attr('width', (d) => Math.abs(x(d.value) - x(0)))
        .attr('fill', '#585ADF');

      bars
        .filter((d) => !!Number(d.value) && !!Number(d.count))
        .append('text')
        .attr('x', (d) => x(d.value) + 10)
        .attr('y', y.bandwidth() / 2 - 10)
        .attr('dy', '0.35em')
        .attr('fill', '#000')
        .attr('font-size', '14px')
        .attr('font-weight', '500')
        .style('font-family', 'Graphik') // Set font family to Graphik
        .text((d) => `${d.value}%`);

      bars
        .filter((d) => !!Number(d.value) && !!Number(d.count))
        .append('text')
        .attr('x', (d) => x(d.value) + 48)
        .attr('y', y.bandwidth() / 2 - 10)
        .attr('dy', '0.35em')
        .attr('fill', '#5F5F8C')
        .attr('font-size', '12px')
        .style('font-family', 'Graphik') // Set font family to Graphik
        .text((d) => `(${d.count} / ${d.total})`);
      // Add the SVG pattern definition

      if (hasComparison) {
        // Previous period bars
        bars
          .append('rect')
          .attr('height', y.bandwidth() / 2 - 3)
          .attr('x', x(0))
          .attr('y', y.bandwidth() / 2 + 7)
          .attr('width', (d) => Math.abs(x(Number(d.comparedValue)) - x(0)))
          .attr('fill', 'url(#custom-pattern)');

        // Conditionally render compared labels only if both comparedValue and comparedCount are valid
        bars
          .filter((d) => !!Number(d.comparedValue) && !!Number(d.comparedCount))
          .append('text')
          .attr('x', (d) => x(Math.max(d.value, Number(d.comparedValue))) + 10)
          .attr('y', y.bandwidth() / 2 + 14)
          .attr('dy', '0.35em')
          .attr('fill', '#000')
          .attr('font-size', '14px')
          .attr('font-weight', '500')
          .style('font-family', 'Graphik') // Set font family to Graphik
          .text((d) => `${d.comparedValue}%`);

        bars
          .filter((d) => !!Number(d.comparedValue) && !!Number(d.comparedCount))
          .append('text')
          .attr('x', (d) => x(Math.max(d.value, Number(d.comparedValue))) + 48)
          .attr('y', y.bandwidth() / 2 + 14)
          .attr('dy', '0.35em')
          .attr('fill', '#5F5F8C')
          .attr('font-size', '12px')
          .style('font-family', 'Graphik') // Set font family to Graphik
          .text((d) => `(${d.comparedCount} / ${d.comparedTotal})`);
        // Custom pattern definition
        const defs = svg.append('defs');
        const pattern = defs
          .append('pattern')
          .attr('id', 'custom-pattern')
          .attr('patternUnits', 'userSpaceOnUse')
          .attr('width', 60)
          .attr('height', 26);

        pattern
          .append('rect')
          .attr('width', 60)
          .attr('height', 22)
          .attr('fill', '#585ADF')
          .attr('fill-opacity', 0.32);

        const innerPattern = pattern
          .append('pattern')
          .attr('id', 'inner-pattern')
          .attr('patternUnits', 'userSpaceOnUse')
          .attr('width', 12)
          .attr('height', 12);

        innerPattern
          .append('image')
          .attr(
            'xlink:href',
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA3SURBVHgB7cyxEQAgCATBl1od2hDrU1vyxQ4gM/DinSuIpKsJaNjsMayTUod9/Ar2UtjnyOA7PxB0QyUOBP/KAAAAAElFTkSuQmCC',
          )
          .attr('width', 12)
          .attr('height', 12);

        pattern
          .append('rect')
          .attr('width', 60)
          .attr('height', 16)
          .attr('fill', 'url(#inner-pattern)');
      }
    }, [data, hasComparison]);

    useEffect(() => {
      drawChart();
    }, [drawChart]);

    return (
      <div style={{width: '100%', height: `${data.length * 60 + 60}px`}}>
        <svg ref={svgRef} width="100%" height="100%" />
      </div>
    );
  },
);
