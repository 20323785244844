import {Avatar} from '@ui/atoms/avatar';
import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {ArrowHeadDownIcon, CancelIcon, MoreVerticalIcon} from '@ui/atoms/icons';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, Headline2, Body1, Microcopy} from '@ui/atoms/typography';
import {Avatars} from '@ui/molecules/avatars';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {
  ResponsiveFlexBetween,
  ManagerViewUser,
  ResponsiveFlexStart,
  ResponsiveFlexRow,
} from '../styles';
import {AveragePulse} from './average-pulse';
import {GoalsProgress} from './goals';
import {IAPIUserFormat, IMeetingResponse} from '@hooks';
import {timezones} from '@constants';
import {authStore} from '@store/stores/auth-store';

import {getTime} from '@utils/date';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {memo, useMemo, useState} from 'react';
import {filterDuplicateItems} from '@utils/array';
import {PlusSVG} from '@ui/atoms/svg';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {capitalize} from '@utils';

interface MeetingHeaderProps {
  data: IMeetingResponse;
  participants: {name: string; id: string; src: string}[];
  isMeetingActive?: boolean;
  isMeetingMissed?: boolean;
  isMeetingNote: boolean;
  isMeetingCanceled?: boolean;
  isMeetingUpcoming: boolean;
  isSingleParticipant: any;
  allParticipants?: IAPIUserFormat[];
  feedbackValues: any;
  isManagerView: boolean;
  managerViewUserOptions: any[];
  markAsCompleted: any;
  setShowViewUser: any;
  handleRemoveTag: () => void;
  refetch: any;
  setOpenRescheduleMeeting: any;
  id: string;
  submitting: boolean;
  reviewee: IAPIUserFormat;
}

interface MeetingBoxProps {
  showDropdown: boolean;
  showButton?: boolean;
  isSubmitting?: boolean;
  handleViewSettings: () => void;
  handleMarkAsCompleted: () => void;
}
const RescheduleMeetingBox = ({
  showDropdown,
  showButton,
  handleViewSettings,
  handleMarkAsCompleted,
  isSubmitting,
}: MeetingBoxProps) => {
  if (showDropdown) {
    return (
      <DropdownVertical
        collapseOnClick
        dropdownWrapperStyle={{
          top: '130%',
          right: '0px',
          width: '160%',
        }}
        menu={(handleClose: () => void) => (
          <DropdownItem
            onClick={() => {
              handleViewSettings();
              handleClose();
            }}>
            Settings
          </DropdownItem>
        )}
        customIcon={<MoreVerticalIcon />}
      />
    );
  }

  if (showButton) {
    return (
      <Button
        style={{padding: '8px 16px'}}
        isLoading={isSubmitting}
        onClick={() => {
          handleMarkAsCompleted();
        }}>
        Mark as completed
      </Button>
    );
  }

  return null;
};

export const MeetingHeader = memo(
  ({
    data,
    participants,
    isMeetingActive,
    refetch,
    feedbackValues,
    markAsCompleted,
    isMeetingNote,
    isMeetingCanceled,
    allParticipants,
    id,
    setOpenRescheduleMeeting,
    managerViewUserOptions,
    reviewee,
    setShowViewUser,
    isSingleParticipant,
    isManagerView,
    handleRemoveTag,
    submitting,
    isMeetingUpcoming,
  }: MeetingHeaderProps) => {
    const {targetHour, targetMinute} = useMemo(
      () => getTime(data?.time || ''),
      [data?.time],
    );

    const [isTooltipFilter, setIsTooltipFilter] = useState(false);

    const navigate = useNavigate();

    const getTimeZone = useMemo(
      () =>
        timezones.find(
          (timezone) => timezone.value === authStore.auth.user.timezone,
        ),
      [],
    );

    const avatarParticipant = useMemo(
      () =>
        filterDuplicateItems(
          data.extraParticipant
            ? [
                ...participants,
                ...data.extraParticipant
                  .filter(
                    (participant) =>
                      !allParticipants
                        ?.map((user) => user.email)
                        .includes(participant.email),
                  )
                  .map((participant) => ({
                    name: participant.email,
                    src: '',
                    id: participant.email,
                  })),
              ]
            : participants,
          'id',
        ),
      [allParticipants, data.extraParticipant, participants],
    );

    const [searchParams] = useSearchParams();

    const updatedDate = useMemo(
      () =>
        dayjs(data.startDate)
          .set('hour', targetHour)
          .set('minute', targetMinute)
          .tz(getTimeZone?.label || '', true),
      [data.startDate, getTimeZone?.label, targetHour, targetMinute],
    );

    const isParticipant = useMemo(
      () => participants.includes(authStore.auth.user.id),
      [participants],
    );

    const meetingTag = data.label?.[0];

    const formatName = isParticipant
      ? userName(data.user)
      : userName(isSingleParticipant);

    return (
      <div>
        <ResponsiveFlexBetween className="w-full">
          <div className="w-full">
            <FlexRowSpaceBetween>
              <div>
                <Headline2 className="">
                  {capitalize(data?.title || `1:1 with ${formatName}`)}
                </Headline2>

                <VerticalSpacer size="8px" />

                <Body2 kind="textBody">
                  {isMeetingUpcoming
                    ? 'This agenda will be saved and you can add more at a later time.'
                    : 'Write your agenda, action points and take notes for this meeting.'}
                </Body2>
              </div>
              <RescheduleMeetingBox
                handleViewSettings={() => {
                  setOpenRescheduleMeeting(true);
                }}
                isSubmitting={submitting}
                handleMarkAsCompleted={() => {
                  searchParams.delete('manager');
                  navigate({search: '?manager=true'});
                  markAsCompleted(
                    id,
                    userName(data.participant),
                    feedbackValues,
                    data.user.id,
                  ).then(() => refetch());
                }}
                showDropdown={
                  data &&
                  !isMeetingCanceled &&
                  data.user?.id === authStore.auth.user.id
                }
                showButton={!!isMeetingActive && isManagerView}
              />
            </FlexRowSpaceBetween>

            <div className="p-4 mt-4 border border-borderLight rounded-[10px] flex flex-row sm:flex-col  sm:items-start items-center gap-2 justify-between  w-full">
              <div className="flex sm:flex-col  flex-row gap-4">
                <div className="flex items-center border border-[#CECEDE] rounded-[10px] px-4 py-3">
                  <Body2 kind="textBody">
                    {dayjs(data.startDate).format("ddd. D MMM. 'YY")}
                  </Body2>

                  {data?.time &&
                    !isMeetingNote &&
                    !data?.title?.includes('Notes with') && (
                      <>
                        <HorizontalSpacer size="10px" />
                        <CustomColorIcon
                          height="8px"
                          width="8px"
                          margin={7}
                          color="#D9D9D9"
                        />
                        <Body2 kind="textBody">
                          {' '}
                          {dayjs(updatedDate).format('hh:mm a')}{' '}
                          {data?.endDate
                            ? `- ${dayjs(data.endDate).format('hh:mm a')}`
                            : null}
                        </Body2>
                      </>
                    )}
                </div>
                <Button
                  kind="secondary"
                  width="sm"
                  // disabled={}
                  onClick={() =>
                    meetingTag ? null : setOpenRescheduleMeeting(true)
                  }
                  style={{padding: '12px 16px'}}
                  className="flex gap-2 items-center">
                  {meetingTag ? (
                    <>
                      <Body1 weight="bold">#</Body1>

                      <Body2 weight="semibold">{meetingTag}</Body2>

                      <button
                        onClick={() => {
                          handleRemoveTag();

                          data.label = [];
                        }}
                        className="ml-1">
                        <CancelIcon />
                      </button>
                    </>
                  ) : (
                    <>
                      <PlusSVG style={{width: '12px', height: '12px'}} />
                      <Microcopy style={{fontWeight: 600}}>Add tag</Microcopy>
                    </>
                  )}
                </Button>
              </div>

              <div
                onMouseEnter={() =>
                  setIsTooltipFilter(isTooltipFilter ? false : true)
                }
                onMouseLeave={() => setIsTooltipFilter(false)}
                className="bg-[#FAFAFA] py-[10px] px-4 border border-[#F6F6F8] rounded-[10px] relative">
                <Avatars
                  showCount={4}
                  tooltip={false}
                  items={avatarParticipant}
                  total={avatarParticipant?.length}
                />
                {isTooltipFilter && (
                  <div className="absolute z-10 gap-2 flex flex-col bg-[#3A4050] rounded-[4px] min-w-[160px] py-4 px-6 border border-[#CECEDE]">
                    {avatarParticipant.map((participant) => (
                      <div key={participant.id} className="">
                        <Body2 className="text-[12px]" kind="white">
                          {participant.name}
                        </Body2>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <VerticalSpacer size="8px" />

            <VerticalSpacer size="8px" />
          </div>
        </ResponsiveFlexBetween>

        {isManagerView && data.type !== 'team' && (
          <>
            <VerticalSpacer size="24px" />
            <ManagerViewUser className="bg-white">
              <ResponsiveFlexStart>
                <ResponsiveFlexRow>
                  <FlexRow className="first-child">
                    <Avatar
                      src=""
                      name={userName(reviewee)}
                      isUserDeleted={!!reviewee?.deletedAt}
                      userId={reviewee?.id || ''}
                      tooltip={true}
                    />
                    <HorizontalSpacer size="16px" />
                    <Body1 kind="textDark" weight="bold">
                      {reviewee?.firstName}`s growth journey
                    </Body1>
                  </FlexRow>
                </ResponsiveFlexRow>

                <DropdownCustom
                  collapseOnClick={true}
                  menu={(handleClose: () => void) => (
                    <>
                      {managerViewUserOptions.map((options, idx) => (
                        <DropdownItem
                          key={idx}
                          onClick={() => {
                            setShowViewUser(options.value as any);
                          }}
                          style={{marginRight: '12px'}}>
                          {options.label}
                        </DropdownItem>
                      ))}
                    </>
                  )}
                  customComponent={(
                    handleOpen: (event: any) => void,
                    open: boolean,
                  ) => (
                    <>
                      <Button
                        kind="secondary"
                        onClick={(event) => {
                          handleOpen(event as any);
                        }}
                        style={{padding: '8px 16px'}}>
                        <FlexRowSpaceBetween style={{width: '100%'}}>
                          <Body2 weight="bold">View history</Body2>

                          <ArrowHeadDownIcon
                            style={{
                              marginLeft: 14,
                              stroke: 'black',
                              transform: !open
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                            }}
                          />
                        </FlexRowSpaceBetween>
                      </Button>
                    </>
                  )}
                  customDropdownWrapperStyles={{
                    top: '130%',
                    right: 0,
                    width: '100%',
                  }}
                />
              </ResponsiveFlexStart>
            </ManagerViewUser>

            {(isMeetingUpcoming || isMeetingActive) && (
              <>
                <VerticalSpacer size="24px" />
                <AveragePulse userId={reviewee?.id || ''} />
                <VerticalSpacer size="24px" />

                <GoalsProgress userId={reviewee?.id || ''} />
              </>
            )}
          </>
        )}
      </div>
    );
  },
);
