import {useStoreContext} from '@store/store-context';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {database} from '@utils/firebase-request';
import dayjs from 'dayjs';
import {ActionType} from '@api/action-items-api/action-items-request';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import {
  equalTo,
  onValue,
  orderByChild,
  query,
  ref,
  off,
} from 'firebase/database';
import {activateNotification} from '@ui/molecules/notification/activate-notification';

interface ActionItemsHookProps {
  itemId: string;
  source: string;
  userId: string;
  callback?: any;
  orderBy?: {
    key: string;
    value: string;
  };
  fetchAll?: boolean; // Add this new prop
}

export const useActionItems = ({
  itemId,
  source,
  userId,
  orderBy,
  callback,
  fetchAll = false,
}: ActionItemsHookProps) => {
  const {
    authStore,
    usersStore: {users},
  } = useStoreContext();

  const actionItemsPath = useMemo(
    () =>
      `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/action_items/`,
    [authStore.auth.user.workspace.id],
  );

  const [firebaseTasks, setTasks] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const databaseRef = useMemo(() => ref(database, actionItemsPath), [
    actionItemsPath,
  ]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    const tasks: Record<string, any> = {};
    const convertAndFilterTasks = (tasks: Record<string, any>) => {
      return Object.entries(tasks).map(([firebaseId, task]: [string, any]) => ({
        ...task,
        firebaseId,
      }));
    };

    const handleSnapshot = (snapshot: any) => {
      if (snapshot.exists()) {
        Object.assign(tasks, snapshot.val());

        const convertedTasks = convertAndFilterTasks(tasks);

        const allTasks = convertedTasks
          .map((task) => ({
            ...task,
            assignee: users.find((user) => user.id === task.assignee),
            key: task.id || task._id || task.key || '',
          }))
          .sort((a, b) => a.order - b.order);

        setTasks(allTasks.length ? allTasks : [{text: '', completed: false}]);
      } else {
        setTasks([]);
      }
      setIsLoading(false);
    };

    const fetchAllData = () => {
      onValue(databaseRef, handleSnapshot);
      return () => off(databaseRef);
    };

    const fetchFilteredData = (orderByField: string, equalToValue: any) => {
      const filteredQuery = query(
        databaseRef,
        orderByChild(orderByField),
        equalTo(equalToValue),
      );

      onValue(filteredQuery, handleSnapshot);
      return () => off(filteredQuery);
    };

    if (fetchAll) {
      return fetchAllData();
    } else {
      const unsubscribers: (() => void)[] = [];

      if (orderBy) {
        unsubscribers.push(fetchFilteredData(orderBy.key, orderBy.value));
      }

      if (userId) {
        unsubscribers.push(fetchFilteredData('assignee', userId));
        unsubscribers.push(fetchFilteredData('user', userId));
      }

      return () => unsubscribers.forEach((unsub) => unsub());
    }
  }, [fetchAll, users, databaseRef, orderBy, userId]); // Add fetchAll to dependencies

  useEffect(() => {
    const unsubscribe = fetchData();

    return unsubscribe;
  }, [fetchData, itemId, userId]);

  const findField = (data: any[], key: string) => {
    return data.find((field) => field.key === key);
  };

  const computeActionItem = (data: any, removeSource?: boolean) => {
    const assignee = data?.assignee;
    const id = data?.id || data?.key || generateRandomDigits(24);

    const computedItem = {
      completed: data.completed,
      id,
      meta: removeSource
        ? null
        : data?.meta || findField(firebaseTasks, id)?.meta || null,
      text: data.text,
      source: removeSource
        ? ''
        : data.source || findField(firebaseTasks, id)?.source || source,
      completedAt:
        findField(firebaseTasks, id)?.completedAt || data.completed
          ? dayjs().format()
          : '',
      sourceId: removeSource
        ? ''
        : data.sourceId || findField(firebaseTasks, id)?.sourceId || itemId,
      section: data?.section ?? findField(firebaseTasks, id)?.section ?? '',
      updatedBy: data.updatedBy || authStore.auth.user.id,
      createdBy:
        findField(firebaseTasks, id)?.createdBy ||
        data.createdBy ||
        authStore.auth.user.id,
      order: data?.order || findField(firebaseTasks, data.key)?.order || null,
      dueDate: data?.dueDate || null,
      createdAt:
        findField(firebaseTasks, id)?.createdAt ||
        data?.createdAt ||
        dayjs().format(),
      user: data?.user || findField(firebaseTasks, id)?.userId || userId,
      assignee: typeof assignee === 'string' ? assignee : assignee?.id || null,
    };

    return computedItem;
  };

  const handleChange = async (
    data: ActionType[],
    action?: 're-sort' | 'user' | 'dueDate' | '' | 'remove' | 'remove-link',
    fieldId?: string,
    item?: ActionType,
  ) => {
    if (action === 're-sort') {
      const formattedData = data
        .filter((value) => !!value.text)
        .map((value, idx) => {
          const computedValue = computeActionItem({...value, order: idx + 1});

          const taskExists = findField(firebaseTasks, value.key)?.firebaseId;

          if (!!taskExists) {
            updateFirebaseData(`action_items/${taskExists}`, computedValue);
          } else {
            writeFirebaseData(`action_items`, computedValue);
          }

          return computedValue;
        });

      callback && callback(formattedData);
      return;
    }

    const taskExists = findField(firebaseTasks, fieldId || '')?.firebaseId;

    if (action === 'remove' && taskExists) {
      removeFirebaseData(`action_items/${taskExists}`);
      fetchData();
      return;
    }

    const actionitem = computeActionItem(item, action === 'remove-link');

    if (!!item && !!actionitem?.text) {
      try {
        if (!!taskExists) {
          await updateFirebaseData(`action_items/${taskExists}`, actionitem);
        } else {
          await writeFirebaseData(`action_items`, actionitem);
        }

        fetchData();
      } catch (error) {
        activateNotification({
          title: 'Error',
          content: 'Something went wrong',
          kind: 'error',
        });
      }
    }
  };

  return {
    handleChange,
    isLoading,
    tasks: firebaseTasks,
  };
};
