import {useQuery} from 'react-query';
import {onValue, ref, query, off} from 'firebase/database';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import {useCallback, useEffect, useState, useMemo} from 'react';
import {authStore} from '@store/stores/auth-store';

export const useFetchHook = (
  description: string | Array<any>,
  callback: any,
  options?: any,
) => {
  return useQuery(description, callback, {
    ...options,
  });
};

export const useFirebaseFetch = (path: string, filter?: any) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const databaseRef = useMemo(
    () =>
      ref(
        database,
        `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/${path}`,
      ),
    [path],
  );

  const filteredQuery = useMemo(
    () => (filter?.equalTo ? query(databaseRef, filter) : databaseRef),
    [databaseRef, filter],
  );

  const fetchData = useCallback(() => {
    setIsLoading(true);
    const listener = onValue(
      filteredQuery,
      (snapshot: any) => {
        setData(snapshot?.val());
        setIsLoading(false);
      },
      (error) => {
        console.error('Firebase fetch error:', error);
        setIsLoading(false);
      },
    );

    return () => {
      off(filteredQuery, 'value', listener);
    };
  }, [filteredQuery]);

  useEffect(() => {
    const cleanup = fetchData();
    return cleanup;
  }, [fetchData]);

  const refetch = useCallback(() => {
    const cleanup = fetchData();
    return () => {
      cleanup();
    };
  }, [fetchData]);

  return {data, isLoading, refetch};
};
