import {observer} from 'mobx-react';
import {useState} from 'react';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Headline3, Body1, Body2} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {PrioritiesDiv} from '../my_checkins-style';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {userName} from '@utils/user-name';
import {useActionItemsWithSortFilter} from '@hooks/action-items';
import {useQuery} from 'react-query';
import {ViewActionItemsSource} from '@pages/dashboard/action-items/view-source';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {ActionItemsSortByFilter} from '@pages/dashboard/action-items/filter';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';

export const Priorities = observer(
  ({
    value,
    handleFormValueChange,
    updatePriorities,
    loadingCheckins,
    debounceUpdatePriorities,
    isReadOnly,
  }: {
    value: {
      currentCheckin: {
        text?: string;
        completed?: boolean;
      }[];
      nextCheckin: {
        text?: string;
        completed?: boolean;
      }[];
      id: string;
    };

    handleFormValueChange: (value: any, field: string) => void;
    updatePriorities: (value: any) => void;
    debounceUpdatePriorities: (value: any) => void;
    isReadOnly: boolean;
    loadingCheckins: boolean;
  }) => {
    const {
      authStore,
      usersStore: {users},
    } = useStoreContext();

    const [viewSource, setViewSource] = useState({
      id: '',
      source: '',
    });

    const selectedUser = authStore.auth.user.id;

    const {
      handleSource,
      handleChange,
      handleSort,
      initGoals,
      sources,
      setSortBy,
      sortBy,
      showCompleted,
      completedTasks,
      updateShowCompleted,
      isCustomFilter,
      firebaseActionItems: data,
    } = useActionItemsWithSortFilter('', selectedUser);

    useQuery(['goals', selectedUser], () => initGoals(1, 'active'), {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

    useQuery(['sources', selectedUser, data], () => handleSource(), {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

    const getSourceDetail = (sourceId: string) => {
      return sources.get(sourceId);
    };

    const sourceItem = !!viewSource.source
      ? viewSource.source === 'goal'
        ? (getSourceDetail(viewSource.id) as any)?.goal
        : (getSourceDetail(viewSource.id) as any)?.meeting
      : undefined;

    const getPriority = handleSort;

    const currentCheckinValue = getPriority || value?.currentCheckin;

    return (
      <PrioritiesDiv className="bg-white">
        {loadingCheckins ? (
          <ItemLoader />
        ) : (
          <>
            <div className="titleDiv">
              <FlexRowSpaceBetween>
                <div>
                  <Headline3 kind="textDark">Action items{'  '}</Headline3>
                  <VerticalSpacer size="5px" />
                  <Body1 kind="textBody">
                    List current & future priorities
                  </Body1>
                </div>
                <FlexRow className="sm:mt-2 mt-0">
                  <ActionItemsSortByFilter
                    sortBy={sortBy}
                    updateSortBy={setSortBy}
                    isCustomFilter={isCustomFilter}
                    userId={selectedUser}
                  />

                  <div className="mx-1">
                    <HorizontalSideRule size="16px" />
                  </div>

                  <Body2 kind="textBody" className="whitespace-nowrap">
                    {completedTasks.length} completed
                  </Body2>

                  <div className="mx-1">
                    <HorizontalSideRule size="16px" />
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      updateShowCompleted(!showCompleted);
                    }}>
                    <Body2 weight="bold" kind="purple300">
                      {showCompleted ? 'Hide' : 'Show'}
                    </Body2>
                  </button>
                </FlexRow>
              </FlexRowSpaceBetween>
            </div>
            <div className="contentDiv">
              <ActionItems
                showSource
                handleViewSource={(data) =>
                  data.source !== 'url' ? setViewSource(data) : null
                }
                handleChange={handleChange}
                disableCheckbox={isReadOnly}
                source="priority"
                isReadOnly={isReadOnly}
                users={users.map((user) => ({
                  label: userName(user),
                  value: user.id,
                  avatar: user.avatar,
                }))}
                sourceId={''}
                value={currentCheckinValue || []}
                userId={authStore.auth.user.id}
              />
            </div>
          </>
        )}

        {!!viewSource.id && (
          <ViewActionItemsSource
            userName={userName(users.find((user) => user.id === selectedUser))}
            onClose={() => setViewSource({id: '', source: ''})}
            type={viewSource.source}
            goal={sourceItem}
            meeting={sourceItem}
          />
        )}
      </PrioritiesDiv>
    );
  },
);
