import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRow, FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {PageLayout} from '../admin-workspace-styled';
import {PerformanceSettings} from './admin-culture-competence/performance-settings';
import styled from 'styled-components';
import {useNavigate} from 'react-router';
import {ContentBox} from '../admin-workspace-styled';
import {useIsRouteActive} from '@utils/route-href';
import {Button} from '@ui/atoms/button';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useAdminWorkspaceHook} from '../admin-workspace-hooks';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {AdminGoalFramework} from './admin-goal-framework/admin-goal-framework.page';
import {WorkspaceUseCase} from '@hooks';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {useState} from 'react';
import {illustrationList} from '@ui/assets/illustrations';
import {LockIcon} from '@ui/atoms/icons';
import {authStore} from '@store/stores/auth-store';

type Props = {};

const Wrapper = styled.div`
  padding: 22px 24px;
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
`;

const WorkspacePerformance = (props: Props) => {
  const isRouteActive = useIsRouteActive();
  const navigate = useNavigate();

  const {handleUseCaseUpdate, handleActionItem} = useAdminWorkspaceHook();

  const {isPerformanceActive, isBillingStandard} = useWorkspaceUseCase();

  const {isActionItemEnabled} = useWorkspaceUseCase();

  const disablePerformance =
    authStore.auth.user.workspace.billing.activeTrial &&
    !authStore.auth.user.workspace.billing.trailCompleted
      ? false
      : !isBillingStandard;

  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const FreeModal = (
    <FreeTierModal
      open={showUpgradePlanModal}
      image={illustrationList.pickup}
      onClose={() => setShowUpgradePlanModal(false)}
      description={`Implement objectives and key results (OKRs) and key performance indicators (KPIs) to align and cascade goals. Conduct quarterly performance reviews with our exclusive Culture & Competence (C&C) method to evaluate and recognise employee alignment with company culture and competence.`}
      title={`Unlock Advanced Team Management`}
    />
  );

  if (isRouteActive('cc-view-settings')) {
    return <PerformanceSettings />;
  }

  if (isRouteActive('goal-settings')) {
    return <AdminGoalFramework />;
  }

  return (
    <PageLayout>
      <Wrapper>
        {FreeModal}
        <FlexRowSpaceBetween>
          <div>
            <FlexRow>
              {disablePerformance && (
                <span className="mr-2">
                  <LockIcon />{' '}
                </span>
              )}{' '}
              <Body1 weight="bold">Performance</Body1>
            </FlexRow>
            <Body2 kind="textBody">
              Use the option for performance features, such as OKRs, KPIs and
              performance reviews.
            </Body2>
          </div>
          <SwitchToggle
            checked={isPerformanceActive}
            onChange={(e) => {
              disablePerformance
                ? setShowUpgradePlanModal(true)
                : handleUseCaseUpdate(
                    WorkspaceUseCase.PERFORMANCE,
                    !isPerformanceActive,
                  );
            }}
          />
        </FlexRowSpaceBetween>
        <VerticalSpacer size="16px" />

        <ContentBox>
          <div className="flex">
            <Body1 weight="bold">Goals - OKRs & KPIs</Body1>
            <FlexRowEnd>
              <Body2 kind="textBody">Included</Body2>
              <div style={{margin: '0 10px'}}>
                <HorizontalSideRule size="32px" />
              </div>
              <Button
                kind="secondary"
                disabled={!isPerformanceActive}
                style={{padding: '8px 16px'}}
                onClick={() => {
                  navigate('goal-settings');
                }}>
                Configure
              </Button>
            </FlexRowEnd>
          </div>
        </ContentBox>
        <VerticalSpacer size="24px" />

        <ContentBox>
          <div className="flex">
            <Body1 weight="bold">Action items</Body1>
            <FlexRowEnd>
              <SwitchToggle
                checked={isActionItemEnabled}
                onChange={() => handleActionItem(!isActionItemEnabled)}
              />
            </FlexRowEnd>
          </div>
        </ContentBox>

        <VerticalSpacer size="24px" />
        <PerformanceSettings />
      </Wrapper>
    </PageLayout>
  );
};

export default WorkspacePerformance;
