import {memo, useMemo, useState} from 'react';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {useActionItemsWithSortFilter} from '@hooks/action-items';
import {useQuery} from 'react-query';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {DropdownItem, ModalWrapper} from '../groups/styles';
import {Button} from '@ui/atoms/button';
import {ViewActionItemsSource} from './view-source';
import {Modal} from '@ui/molecules/modal';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon, MoreVerticalIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ModalCard} from '@ui/layouts/modal-card';
import {TextField} from '@ui/molecules/field/textfield';
import validator from 'validator';
import {updateActionItemsFilter} from '@utils/firebase-request';
import {updateFirebaseData} from '@utils/firebase-handler';
import {removeUndefinedFromObject} from '@utils/data-structure-algos';
import {useSections} from '@hooks/action-items-sections';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {SectionMenu} from '@ui/layouts/section-card/section-toggle';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {ItemLoader} from '@ui/organisms/item-loader';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {ActionItemsFilter, ActionItemsSortByFilter} from './filter';
import {ActionType} from '@api/action-items-api/action-items-request';

// Function to find and replace the item if it exists
const findAndReplaceTask = (tasks: any[], item: ActionType) => {
  return tasks.map((task) => {
    if (task.key === item.key) {
      return item;
    }
    return task;
  });
};

// Main function to update the tasks
const updateTasks = (
  prevTasks: any[],
  action?: 'remove' | '',
  fieldId?: string,
  item?: ActionType,
) => {
  if (action === 'remove') {
    return prevTasks.filter((task) => task.key !== fieldId);
  }

  if (!item) return prevTasks; // Early return if no item is passed

  const itemExists = prevTasks.find((task) => task.key === item.key);

  if (itemExists) {
    return findAndReplaceTask(prevTasks, item);
  }

  return prevTasks;
};

export const ActionItemsPage = memo(() => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const [viewSource, setViewSource] = useState({
    id: '',
    source: '',
  });

  const [userFilter, setUserFilter] = useState(auth.user.id);

  const [showAddSectionModal, setShowAddSectionModal] = useState(false);

  const selectedUser = userFilter;

  const getManager =
    users.find((user) => user?.id === selectedUser)?.reviewer?.id || '';

  const selectedManager = getManager;

  const {
    handleSource,
    handleChange,
    goalOptions,
    isLoading,
    initGoals,
    sources,
    firebaseActionItems: data,
    showCompleted,
    setTasks,
    isCustomFilter,
    sortBy,
    updateShowCompleted,
    completedTasks,
    groupBySections,
    setSortBy,
  } = useActionItemsWithSortFilter(selectedManager, selectedUser);

  useQuery(['goals', selectedUser], () => initGoals(1, 'active'), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useQuery(['sources', selectedUser, data], () => handleSource());

  const getSourceDetail = (sourceId: string) => {
    return sources.get(sourceId);
  };

  const [selectedSection, setSectionSelection] = useState('');

  // Sections
  const {
    setSectionTitle,
    canCreateSection,
    findSectionSlug,
    handleCreateSection,
    computeSections,
    sectionTitle,
  } = useSections();

  const actionItems = useMemo(() => {
    return selectedSection
      ? groupBySections(computeSections).filter(
          ([key]) => key === selectedSection,
        )
      : groupBySections(computeSections);
  }, [selectedSection, groupBySections, computeSections]);

  const sourceItem = !!viewSource.source
    ? viewSource.source === 'goal'
      ? (getSourceDetail(viewSource.id) as any)?.goal
      : (getSourceDetail(viewSource.id) as any)?.meeting
    : undefined;

  return (
    <>
      <FlexRowSpaceBetween className="mb-6">
        <div className="">
          <Headline2>Action Items</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Create and manage priority lists, set due dates, track progress,
              and mark tasks complete.
            </Body1>
          </div>
        </div>
      </FlexRowSpaceBetween>
      <div className="flex items-row gap-6">
        <div className="w-full">
          <div className="bg-white rounded-t-[10px] border border-borderLight px-6 py-4">
            <div className="flex sm:items-start items-center justify-between sm:flex-col flex-row">
              <ActionItemsFilter
                onChange={(value) => {
                  setUserFilter(value);
                }}
                value={{users: selectedUser}}
              />
              <FlexRow className="sm:mt-2 mt-0">
                <ActionItemsSortByFilter
                  sortBy={sortBy}
                  updateSortBy={setSortBy}
                  isCustomFilter={isCustomFilter}
                  userId={selectedUser}
                />
                <div className="mx-1">
                  <HorizontalSideRule size="16px" />
                </div>
                <Body2 kind="textBody" className="whitespace-nowrap">
                  {completedTasks.length} completed
                </Body2>
                <div className="mx-1">
                  <HorizontalSideRule size="16px" />
                </div>
                <button
                  onClick={() => {
                    updateShowCompleted(!showCompleted);
                  }}>
                  <Body2 weight="bold" kind="purple300">
                    {showCompleted ? 'Hide' : 'Show'}
                  </Body2>
                </button>
              </FlexRow>
            </div>
            {canCreateSection ? (
              <FlexRow className="gap-2 pt-3 border-t mt-3 border-t-[#EDEDF2] flex-wrap">
                <button
                  onClick={() => setSectionSelection('')}
                  className={`py-1 px-2 ${
                    selectedSection ? 'bg-[#F6F6F8]' : 'bg-[#585ADF]'
                  }  rounded-lg flex items-center gap-1 cursor-pointer`}>
                  <Body2 kind={selectedSection ? 'textBody' : 'white'}>
                    All
                  </Body2>
                  <div className="border border-borderLight bg-white rounded-md py-[3px] px-[6px]">
                    <Body2 size="9px" weight="bold">
                      {data.length}
                    </Body2>
                  </div>
                </button>
                {computeSections.map((section) => (
                  <button
                    onClick={() => setSectionSelection(section.name)}
                    className={`py-1 px-2 ${
                      selectedSection !== section.name
                        ? 'bg-[#F6F6F8]'
                        : 'bg-[#585ADF]'
                    } rounded-lg flex items-center gap-1 cursor-pointer`}
                    key={section.slug}>
                    <Body2
                      kind={
                        selectedSection !== section.name ? 'textBody' : 'white'
                      }>
                      {section.name}
                    </Body2>
                  </button>
                ))}
                <button
                  className="py-1 px-2 border border-[#CECEDE] rounded-lg flex items-center gap-1"
                  onClick={() => setShowAddSectionModal(true)}>
                  <SecondaryPlusIcon stroke="#5F5F8C" />
                  <Body2 weight="bold" kind="textBody">
                    New Section
                  </Body2>
                </button>
              </FlexRow>
            ) : null}
          </div>

          <div className="mt-4">
            {isLoading && <ItemLoader />}

            {actionItems &&
              !isLoading &&
              (actionItems as any).map(([key, actionItems]: any) => (
                <div className="mb-4" key={key}>
                  <ActionItemsSections
                    actionItems={actionItems}
                    data={data}
                    setViewSource={setViewSource}
                    sources={sources}
                    name={key}
                    sectionSlug={findSectionSlug(key) || ''}
                    getSourceDetail={getSourceDetail}
                    handleChange={(data, action, fieldId, item) => {
                      if (action !== 'add-url')
                        handleChange(data, action, fieldId, item);

                      if (action !== 're-sort')
                        setTasks((prev) =>
                          updateTasks(prev, action as any, fieldId, item),
                        );
                    }}
                    goalOptions={goalOptions}
                    selectedUser={selectedUser}
                  />
                </div>
              ))}
          </div>
        </div>

        <Modal
          open={showAddSectionModal}
          onClose={() => setShowAddSectionModal(false)}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setShowAddSectionModal(false)}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>New section </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={sectionTitle}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(event) => setSectionTitle(event.target.value)}
                  name="name"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() =>
                    handleCreateSection().then(() => {
                      setSectionTitle('');
                      setShowAddSectionModal(false);
                    })
                  }
                  data-form-action={true}
                  disabled={!sectionTitle || sectionTitle.length > 25}>
                  Create
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>

        {!!viewSource.id && (
          <ViewActionItemsSource
            userName={userName(users.find((user) => user.id === selectedUser))}
            onClose={() => setViewSource({id: '', source: ''})}
            type={viewSource.source}
            goal={sourceItem}
            meeting={sourceItem}
          />
        )}
      </div>
    </>
  );
});

interface ActionItemsSectionProps {
  getSourceDetail: (sourceId: string) => any;
  goalOptions: any[];
  selectedUser: string;
  actionItems: any[];
  name: string;
  sectionSlug: string;
  sources?: any;
  handleChange: (
    value: ActionType[],
    actionType?:
      | 'dueDate'
      | 'user'
      | 're-sort'
      | ''
      | 'remove'
      | 'remove-link'
      | 'add-url',
    fieldId?: string,
    item?: ActionType,
  ) => void;
  setViewSource: any;
  data: any[];
}

const ActionItemsSections = memo(
  ({
    goalOptions,
    name,
    actionItems = [],
    setViewSource,
    data,
    sectionSlug,
    selectedUser,
    handleChange,
  }: ActionItemsSectionProps) => {
    const [showAddUrl, setShowAddUrl] = useState('');

    // Sections
    const {
      setSectionTitle,
      handleEditSection,
      showRenameSectionModal,
      setRenameSectionModal,
      showDeleteModal,
      setShowDeleteModal,
      handleDeleteSection,
      computeSections,
      sectionTitle,
    } = useSections();

    const [urlFormData, setUrlFormData] = useState({
      title: '',
      url: '',
    });

    const handleUpdateUrlFormData = (field: string, value: string) => {
      setUrlFormData((prev) => ({...prev, [field]: value}));
    };

    const handleAddUrl = (fieldId: string) => {
      const updatedItemIndex = actionItems.findIndex(
        (item) => item.key === fieldId,
      );

      actionItems[updatedItemIndex] = {
        ...actionItems[updatedItemIndex],
        meta: urlFormData,
        source: 'url',
      };

      const item = actionItems[updatedItemIndex];

      if (item.firebaseId) {
        handleChange(actionItems, 'add-url', item?.key, item);
        updateFirebaseData(
          `action_items/${item.firebaseId}`,
          removeUndefinedFromObject(item),
        );
      }

      setShowAddUrl('');
      setUrlFormData({title: '', url: ''});
    };

    const isUrlValid = !!urlFormData.url && validator.isURL(urlFormData.url);

    const getActionItem = (id: string) =>
      actionItems.find((value: {id: string}) => value.id === id);

    const canEdit = !!sectionSlug;

    const firebaseId = computeSections?.find(
      (section) => section.slug === sectionSlug,
    )?.key;

    const {
      usersStore: {users},
      authStore: {auth},
    } = useStoreContext();

    return (
      <SectionMenu
        title={name}
        action={
          canEdit ? (
            <>
              <DropdownVertical
                collapseOnClick
                dropdownWrapperStyle={{
                  right: '0px',
                }}
                menu={(handleClose: () => void) => (
                  <>
                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        setRenameSectionModal(true);
                      }}>
                      Rename
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        setShowDeleteModal(true);
                      }}>
                      Delete
                    </DropdownItem>
                  </>
                )}
                customIcon={<MoreVerticalIcon />}
              />
            </>
          ) : null
        }
        count={actionItems.length}
        style={{background: 'white', borderColor: '#E1E1EA'}}>
        <div className="p-6">
          <ActionItems
            showSource
            section={sectionSlug}
            onDrag={() => {
              updateActionItemsFilter(selectedUser, {
                type: 'custom',
              });
            }}
            handleViewSource={(data) =>
              data.source !== 'url' ? setViewSource(data) : null
            }
            onHandleDropdownClick={(fieldId, id) => {
              if (id === 'url') {
                setShowAddUrl(fieldId);
              }
            }}
            handleLinkGoal={(fieldId, goalId) => {
              const updatedItemIndex = data.findIndex(
                (item) => item.key === fieldId,
              );

              data[updatedItemIndex] = {
                ...data[updatedItemIndex],
                source: 'goal',
                sourceId: goalId,
              };
              let updatedItem = data[updatedItemIndex];

              handleChange(data, '', fieldId, updatedItem);
            }}
            goalOptions={goalOptions}
            canDelete={(fieldId) => {
              const task = actionItems.find((task) => task.key === fieldId);

              if (!!task) {
                // allow admin delete action item
                if (auth.user.role === 'admin' && !!task) {
                  return true;
                }

                // allow manager of task users delete task
                const usersInvolved = users
                  .filter((user) =>
                    [task?.user, task?.updatedBy, task?.assignee].includes(
                      user?.id,
                    ),
                  )
                  .map((user) => user.reviewer?.id);

                if (usersInvolved.includes(auth.user?.id)) {
                  return true;
                }
              }
              return false;
            }}
            users={users.map((user) => ({
              label: userName(user),
              value: user.id,
              avatar: user.avatar,
            }))}
            dropdownComponents={(fieldId: string) =>
              !getActionItem(fieldId)?.source
                ? [
                    {
                      content: `Link with a goal`,
                      id: 'goal',
                    },
                    {
                      content: `Add a URL`,
                      id: 'url',
                    },
                  ]
                : [
                    {
                      content: `Remove link`,
                      id: 'remove-link',
                    },
                  ]
            }
            handleChange={async (value, actionType, fieldId, item) => {
              await handleChange(value, actionType, fieldId, {
                ...item,
                assignee:
                  actionType === 'user'
                    ? item?.assignee
                    : item?.assignee || selectedUser,
              } as any);
            }}
            source=""
            sourceId={'general'}
            value={actionItems}
            userId={auth.user.id}
          />
        </div>

        <Modal open={!!showAddUrl} onClose={() => setShowAddUrl('')}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setShowAddUrl('')}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Add URL </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={urlFormData.title}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(value) =>
                    handleUpdateUrlFormData('title', value.target.value)
                  }
                  name="name"
                />

                <TextField
                  label="Add URL"
                  value={urlFormData.url}
                  state={isUrlValid || !urlFormData.url ? 'default' : 'error'}
                  helper={isUrlValid || !urlFormData.url ? '' : 'Invalid url'}
                  placeholder="Paste link (use https://)"
                  onChange={(value) =>
                    handleUpdateUrlFormData('url', value.target.value)
                  }
                  name="url"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() => handleAddUrl(showAddUrl)}
                  data-form-action={true}
                  disabled={!isUrlValid || !urlFormData.title}>
                  Apply
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
        <Modal
          open={showRenameSectionModal}
          onClose={() => setRenameSectionModal(false)}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => setRenameSectionModal(false)}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Rename section </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={sectionTitle}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(event) => setSectionTitle(event.target.value)}
                  name="name"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() =>
                    handleEditSection(firebaseId || '', sectionTitle).then(
                      () => {
                        setSectionTitle('');
                        setRenameSectionModal(false);
                      },
                    )
                  }
                  data-form-action={true}
                  disabled={!sectionTitle || sectionTitle.length > 25}>
                  Create
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>

        <CustomIconModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          customButton={
            <Button
              kind="secondary"
              width="full"
              onClick={() => handleDeleteSection(firebaseId)}
              type="button">
              Delete
            </Button>
          }
          body={<></>}
          onClick={() => {}}
          title={'Delete Section?'}
          description={
            <div>
              <Body2 align="center">
                Are you sure you want to delete{' '}
                <span className="text-[#585ADF] font-medium text-[16px]">
                  {name}
                </span>
                ? This action cannot be undone.
              </Body2>
            </div>
          }
        />
      </SectionMenu>
    );
  },
);
