import {observer} from 'mobx-react';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Controller} from 'react-hook-form';
import {weekdays} from '../../../../../../../../constants';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {Button} from '../../../../../../../../ui/atoms/button';
import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {
  Body1,
  Body2,
  CTA,
  Headline4,
} from '../../../../../../../../ui/atoms/typography';
import {useBreakpoints} from '@utils/use-breakpoints';
import {SectionCard} from '@ui/layouts/section-card';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {SelectField} from '@ui/molecules/select/selectfield';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {capitalize} from '@utils';
import {useAdminCheckinsHook} from '../admin-checkins.hooks';
import {
  EditCheckinsInnerWrapper,
  EditCheckinsOuterWrapper,
  BreadcrumbWrapper,
  SectionComponentWrapper,
} from '../admin-checkins.styles';
import {useNavigate} from 'react-router';
import {FlexRow} from '@ui/style/styles';
import {checkinName} from '@utils/checkin-name';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';

interface IEditCheckins {
  setIsConfiguring: Dispatch<SetStateAction<boolean>>;
}

interface ISettingsCardProps {
  title: string;
  message: string;
  checked: boolean;
  handleChecked: (value: boolean) => void;
}

const SettingToggleCard = ({
  title,
  message,
  checked,
  handleChecked,
}: ISettingsCardProps) => {
  return (
    <div className="p-6 flex items-center justify-between rounded-[10px] border border-borderLight">
      <div>
        <FlexRow>
          <Body1 weight="bold">{title}</Body1>
        </FlexRow>
        {message && <Body2 kind="textBody">{message}</Body2>}
      </div>
      <SwitchToggle
        checked={checked}
        onChange={() => handleChecked(!checked)}
      />
    </div>
  );
};

export const EditCheckins = observer(({setIsConfiguring}: IEditCheckins) => {
  const {
    errors,
    control,
    handleFormValueChange,
    isEditingCheckinsFrequency,
    handleSubmit,
    isSubmitting,
    isEditingCheckinsDueDayFrequency,
    handleEditCheckinsDueDayFrequency,
    handleActiveCheckins,
    handleSaveCheckinsDueDayFrequency,
    handleEditCheckinsFrequency,
    handleSaveCheckinsFrequency,
    disabled,
    submitForm,
    loading,
  } = useAdminCheckinsHook();

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
    checkinsStore,
  } = useStoreContext();

  const navigate = useNavigate();

  const breadcrumbNav = useMemo(() => {
    const nav = [
      {
        title: 'Engagement',
        href: '#',
        id: '1',
        onClick: () => {
          setIsConfiguring(false);
          navigate(-1);
        },
      },
      {
        title: capitalize(checkinName()),
        href: '#',
        id: '2',
        isCurrent: true,
        onClick: () => {
          setIsConfiguring(true);
        },
      },
    ];
    return nav;
  }, [setIsConfiguring, navigate]);

  const isEditing = Object.keys(checkinsStore.configuration).length > 0;

  const [groupOptions, setgroupOptions] = useState<any[]>([]);

  const [loadingGroups, setLoadingGroups] = useState<any>(false);

  const fetchGroup = useCallback(
    async (groupTypeId: string, name: string) => {
      try {
        setLoadingGroups(true);
        if (groups) {
          const _groups = groups.filter((grp) => grp.groupType === groupTypeId);

          setgroupOptions((prev) => [
            ...prev,
            {
              name: name,
              data:
                _groups &&
                _groups.map((group: any) => ({
                  label: group.name,
                  value: group.id,
                })),
            },
          ]);
        }
        setLoadingGroups(false);
      } catch (error) {
        setLoadingGroups(false);
      }
    },
    [groups],
  );

  useEffect(() => {
    groupType.map((itm) => fetchGroup(itm?.id, itm.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupType]);

  const {isXs} = useBreakpoints();

  return (
    <EditCheckinsOuterWrapper
      method={isEditing ? 'patch' : 'post'}
      onSubmit={handleSubmit(submitForm)}>
      <BreadcrumbWrapper>
        <SecondaryBreadcrumb
          wrapperStyles={{padding: '24px 32px 12px', marginBottom: 0}}
          navigation={breadcrumbNav}
        />
      </BreadcrumbWrapper>
      <EditCheckinsInnerWrapper>
        {
          <>
            {loading ? (
              <ItemLoaderLine />
            ) : (
              <>
                <SectionCard
                  title={`${capitalize(checkinName())} frequency`}
                  headerPadding={isXs ? 'small' : undefined}
                  action={
                    <Button
                      kind={
                        isEditingCheckinsFrequency ? 'primary' : 'secondary'
                      }
                      style={{padding: '8px 16px'}}
                      onClick={
                        isEditingCheckinsFrequency
                          ? handleSaveCheckinsFrequency
                          : handleEditCheckinsFrequency
                      }
                      type="button">
                      {isEditingCheckinsFrequency ? 'Save' : 'Edit'}
                    </Button>
                  }
                  contentStyling={{
                    padding: isXs ? '0px' : '0px 0px 10px 0px',
                  }}
                  CustomHeaderTitle={
                    <div className="flex items-center">
                      <Headline4 kind="textDark">
                        {capitalize(checkinName())} frequency
                      </Headline4>
                    </div>
                  }>
                  <SectionComponentWrapper>
                    <>
                      <CTA kind="textDark" className="mb-1">
                        How often should users submit their {checkinName()}s?
                      </CTA>

                      <Controller
                        control={control}
                        name={'generalFrequency.frequency'}
                        render={({onBlur, value}) => (
                          <>
                            <SelectField
                              onBlur={onBlur}
                              value={value}
                              margin
                              helper={
                                (errors?.generalFrequency as any)?.message || ''
                              }
                              state={
                                !!(errors as any)?.generalFrequency
                                  ? 'error'
                                  : 'default'
                              }
                              onChange={(data: {value: string}) => {
                                handleFormValueChange(
                                  data?.value,
                                  `generalFrequency.frequency`,
                                );
                              }}
                              placeholder={'Weekly'}
                              borderRadius="10px"
                              options={['weekly', 'monthly', 'quarterly'].map(
                                (day) => ({
                                  label: capitalize(day),
                                  value: day,
                                }),
                              )}
                              disabled={!isEditingCheckinsFrequency}
                            />

                            {(value === 'monthly' || value === 'quarterly') && (
                              <>
                                <CTA kind="textDark" className="mb-1 mt-2">
                                  How often should users submit their{' '}
                                  {checkinName()}s?
                                </CTA>
                                <Controller
                                  control={control}
                                  name={`generalFrequency.week`}
                                  render={({onBlur, value: runTimeValue}) => (
                                    <SelectField
                                      onBlur={onBlur}
                                      margin
                                      value={runTimeValue}
                                      helper={
                                        (errors?.generalFrequency as any)?.week
                                          ?.message || ''
                                      }
                                      state={
                                        !!(errors as any)?.generalFrequency
                                          ?.week
                                          ? 'error'
                                          : 'default'
                                      }
                                      onChange={(data: {value: string}) => {
                                        handleFormValueChange(
                                          data?.value,
                                          `generalFrequency.week`,
                                        );
                                      }}
                                      borderRadius="10px"
                                      placeholder={'Select run time'}
                                      options={
                                        value === 'monthly'
                                          ? [
                                              {
                                                label:
                                                  'First week of the month',
                                                value: 'first',
                                              },
                                              {
                                                label: 'Last week of the month',
                                                value: 'last',
                                              },
                                            ]
                                          : [
                                              {
                                                label:
                                                  'First week of the quarter',
                                                value: 'first',
                                              },
                                              {
                                                label:
                                                  'Mid-Quarter (the week of the 15th)',
                                                value: 'mid',
                                              },
                                              {
                                                label:
                                                  'Last week of the quarter',
                                                value: 'last',
                                              },
                                            ]
                                      }
                                      disabled={!isEditingCheckinsFrequency}
                                    />
                                  )}
                                />
                              </>
                            )}
                          </>
                        )}
                      />
                    </>
                  </SectionComponentWrapper>
                </SectionCard>
                <VerticalSpacer size="24px" />

                <SectionCard
                  title="Due day"
                  headerPadding={isXs ? 'small' : undefined}
                  action={
                    <Button
                      kind={
                        isEditingCheckinsDueDayFrequency
                          ? 'primary'
                          : 'secondary'
                      }
                      style={{padding: '8px 16px'}}
                      onClick={
                        isEditingCheckinsDueDayFrequency
                          ? handleSaveCheckinsDueDayFrequency
                          : handleEditCheckinsDueDayFrequency
                      }
                      type="button">
                      {isEditingCheckinsDueDayFrequency ? 'Save' : 'Edit'}
                    </Button>
                  }
                  contentStyling={{
                    padding: isXs ? '0px' : '0px 0px 10px 0px',
                  }}
                  CustomHeaderTitle={
                    <div className="flex items-center">
                      <Headline4 kind="textDark">Due day </Headline4>
                    </div>
                  }>
                  <SectionComponentWrapper>
                    <>
                      <CTA kind="textDark" className="mb-1">
                        What day of the week should {checkinName()}s be due?
                      </CTA>
                      <Controller
                        control={control}
                        name={`generalFrequency.dueDay`}
                        render={({onBlur, value}) => (
                          <SelectField
                            onBlur={onBlur}
                            margin
                            value={value}
                            helper={
                              (errors?.generalFrequency as any)?.message || ''
                            }
                            state={
                              !!(errors as any)?.generalFrequency
                                ? 'error'
                                : 'default'
                            }
                            onChange={(data: {value: string}) => {
                              handleFormValueChange(
                                data?.value,
                                `generalFrequency.dueDay`,
                              );
                            }}
                            placeholder={'Friday'}
                            options={weekdays.map((day) => ({
                              ...day,
                              value: capitalize(day?.value),
                            }))}
                            disabled={!isEditingCheckinsDueDayFrequency}
                          />
                        )}
                      />
                    </>
                  </SectionComponentWrapper>
                </SectionCard>

                <VerticalSpacer size="24px" />

                <Controller
                  control={control}
                  name={`generalFrequency.activeCheckins`}
                  render={({onBlur, value}) => (
                    <>
                      <SettingToggleCard
                        title={`Mood Check`}
                        message=""
                        checked={value.includes('pulse')}
                        handleChecked={(value) => {
                          handleActiveCheckins('pulse');
                        }}
                      />
                      <VerticalSpacer size="24px" />

                      <SettingToggleCard
                        title={`Action items`}
                        message=""
                        checked={value.includes('priorities')}
                        handleChecked={(value) => {
                          handleActiveCheckins('priorities');
                        }}
                      />
                    </>
                  )}
                />

                <VerticalSpacer size="24px" />
                <Controller
                  control={control}
                  name={`allowLateSubmission`}
                  render={({onBlur, value}) => (
                    <SettingToggleCard
                      title={`Allow late submissions`}
                      message="With this option, you can manually send reminders"
                      checked={value}
                      handleChecked={(value) => {
                        handleFormValueChange(value, 'allowLateSubmission');
                      }}
                    />
                  )}
                />

                <VerticalSpacer size="24px" />
                <SectionCard
                  title="Exclude groups"
                  headerPadding={isXs ? 'small' : undefined}
                  contentStyling={{
                    padding: isXs ? '0px' : '0px 0px 10px 0px',
                  }}
                  CustomHeaderTitle={
                    <div>
                      <Headline4 kind="textDark">
                        Exclude groups from {checkinName()}s submission
                      </Headline4>
                      <Body2 kind="textBody">
                        Select which group should be exempted from{' '}
                        {checkinName()}s
                      </Body2>
                    </div>
                  }>
                  <SectionComponentWrapper>
                    {loadingGroups && <ItemLoaderLine />}
                    {!loadingGroups && (
                      <>
                        <CTA kind="textDark">Groups</CTA>
                        <Controller
                          control={control}
                          name={`excludedGroups`}
                          render={({onBlur, value}) => (
                            <MultiOptionsSelectFieldGroup
                              options={groupOptions}
                              performance={true}
                              searchPlaceholder="Search group"
                              groupDisplayLength={5}
                              noOptionsMessage="Group not found"
                              placeholder="Select Groups"
                              defaultValue={value?.map((val?: string) => ({
                                label: groups?.find(
                                  (value) => value?.id === val,
                                )?.name,
                                value: val,
                              }))}
                              onChange={(data: any) => {
                                handleFormValueChange(
                                  data.map(
                                    (group: {label: string; value: string}) =>
                                      group.value,
                                  ),
                                  'excludedGroups',
                                );
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </SectionComponentWrapper>
                </SectionCard>
                <VerticalSpacer size="24px" />
                <VerticalSpacer size="16px" />
                <VerticalSpacer size="24px" />
                <Button
                  width="full"
                  type="submit"
                  data-form-action={true}
                  isLoading={isSubmitting}
                  disabled={isEditingCheckinsFrequency || disabled}
                  aria-busy={isSubmitting}>
                  Save
                </Button>
              </>
            )}
          </>
        }
      </EditCheckinsInnerWrapper>
    </EditCheckinsOuterWrapper>
  );
});
