import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo, useCallback, useState, useEffect} from 'react';
import {useForm, Resolver} from 'react-hook-form';
import {WorkspaceAPIController} from '../../../../../../../api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '../../../../../../../api/workspaces-api/workspaces-api-request';
import {useStoreContext} from '../../../../../../../store/store-context';
import {AnalyticsController} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {WorkspaceCheckinsSettingsController} from './admin-checkins.controller';
import {WorkspaceCheckinsSettingsForm} from './admin-checkins.interface';
import {WorkspaceCheckinsSettingsRequestImpl} from './admin-checkins.request';
import {AdminConfigureCheckinsValidator} from './admin-checkins.validator';
import {useNavigate} from 'react-router';

export function useAdminCheckinsHook() {
  // 01. General ---- Shared functions and constants

  const {
    analyticsStore: {setFilterGroup},
    authStore: {auth, setAuthResponse},
    checkinsStore: {configuration, setCheckinsConfiguration},
  } = useStoreContext();

  // --- UI
  const [loading, setLoading] = useState(false);
  const [isEditingCheckinsFrequency, setIsEditingCheckinsFrequency] = useState(
    false,
  );
  const [disabled, setDisabled] = useState(true);

  const handleEditCheckinsFrequency = () => {
    setIsEditingCheckinsFrequency(true);
  };

  const handleSaveCheckinsFrequency = () => {
    setIsEditingCheckinsFrequency(false);
  };
  const handleEditCheckinsDueDayFrequency = () => {
    setIsEditingCheckinsDueDayFrequency(true);
  };

  const handleSaveCheckinsDueDayFrequency = () => {
    setIsEditingCheckinsDueDayFrequency(false);
  };

  const navigate = useNavigate();

  // Visibility settings
  const [selectedVisibility, setSelectedVisibility] = useState('full');
  //
  const workspaceAPIRequest = useMemo(() => new WorkspaceAPIRequestImpl(), []);
  const workspaceAPIController = useMemo(
    () => new WorkspaceAPIController(workspaceAPIRequest),
    [workspaceAPIRequest],
  );
  const workspaceCheckinsRequest = useMemo(
    () => new WorkspaceCheckinsSettingsRequestImpl(),
    [],
  );
  const workspaceCheckinsController = useMemo(
    () => new WorkspaceCheckinsSettingsController(workspaceCheckinsRequest),
    [workspaceCheckinsRequest],
  );

  const editableCheckinsConfig = Object.keys(configuration).length > 0;
  const [editableConfigData, setEditableConfigData] = useState(configuration);

  // 03. Update workspace setting to enable/disable checkins
  const [isConfiguring, setIsConfiguring] = useState(false);

  const isCheckinsEnabled =
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const firstTimeEnable =
    Object.keys(configuration)?.length === 0 &&
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const enableCheckins = useCallback(async () => {
    const response = await workspaceAPIController.updateWorkspace(
      {
        settings: {
          checkInStatus: 'enabled',
        },
      },
      true,
    );
    response &&
      setAuthResponse({
        ...auth,
        user: {
          ...auth?.user,
          workspace: {
            ...auth?.user?.workspace,
            settings: {
              ...auth?.user?.workspace?.settings,
              checkInStatus: 'enabled',
            },
          },
        },
      });
  }, [auth, setAuthResponse, workspaceAPIController]);

  // 04. Configure Checkins Form
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    reset,
  } = useForm<WorkspaceCheckinsSettingsForm>({
    resolver: yupResolver(AdminConfigureCheckinsValidator) as Resolver<
      WorkspaceCheckinsSettingsForm,
      object
    >,
    mode: 'all',
    defaultValues: {
      excludedGroups: editableCheckinsConfig
        ? configuration?.excludedGroups
        : [],
      activeCustomFrequency: editableCheckinsConfig
        ? configuration?.activeCustomFrequency
        : false,
      visibility: editableCheckinsConfig ? configuration?.visibility : 'full',
      managersCanAddQuestions: editableCheckinsConfig
        ? configuration?.managersCanAddQuestions
        : true,
      allowLateSubmission: editableCheckinsConfig
        ? configuration?.allowLateSubmission
        : false,
      generalFrequency: editableCheckinsConfig
        ? configuration?.generalFrequency
        : {
            week: editableCheckinsConfig
              ? configuration?.generalFrequency?.week
              : null,
            frequency: editableCheckinsConfig
              ? configuration?.generalFrequency?.frequency
              : 'weekly',
            activeCheckins: editableCheckinsConfig
              ? configuration?.generalFrequency?.activeCheckins
              : [],
          },
    },
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState.isSubmitting]);

  const handleFormValueChange = useCallback(
    (value: any, field: string) => {
      setDisabled((prev) => prev === true && false);
      if (value) {
        setValue(field, value);
      } else {
        setValue(field, null);
      }
    },
    [setValue],
  );

  const handleActiveCheckins = useCallback(
    (field: string) => {
      setValue(
        'generalFrequency.activeCheckins',
        (currentActiveCheckins: any) => {
          const updatedActiveCheckins = currentActiveCheckins || [];

          const fieldIndex = updatedActiveCheckins.indexOf(field);

          if (fieldIndex > -1) {
            // Remove the field if it's already present
            return updatedActiveCheckins.filter(
              (checkin: string) => checkin !== field,
            );
          } else {
            // Add the field if it's not present
            return [...updatedActiveCheckins, field];
          }
        },
      );
    },
    [setValue],
  );

  ////
  const {activeCustomFrequency} = watch();

  // UI 2.0
  /// Custom Frequency
  const [
    isEditingCustomCheckinsFrequency,
    setIsEditingCustomCheckinsFrequency,
  ] = useState(false);

  const [
    isEditingCheckinsDueDayFrequency,
    setIsEditingCheckinsDueDayFrequency,
  ] = useState(false);

  const [togglCustomFrequency, setTogglCustomFrequency] = useState(
    activeCustomFrequency,
  );

  const [advancedSettingsExpanded, setAdvancedSettingsExpanded] = useState(
    true,
  );

  useEffect(() => {
    if (activeCustomFrequency !== togglCustomFrequency) {
      setTogglCustomFrequency(activeCustomFrequency);
    }
  }, [activeCustomFrequency, togglCustomFrequency]);

  // 02. Initializers
  //a. Init Checkins Config Data
  const getCheckinsConfigData = useCallback(async () => {
    setLoading(true);
    const response = await workspaceCheckinsController.getCheckinsSettings();
    response && setCheckinsConfiguration(response);
    setLoading(false);
  }, [setCheckinsConfiguration, workspaceCheckinsController]);

  useEffect(() => {
    getCheckinsConfigData();
  }, [getCheckinsConfigData]);

  //b. Init editable data
  useEffect(() => {
    setEditableConfigData(configuration);
  }, [configuration]);

  const insertDefaultData = useCallback(async () => {
    reset({
      excludedGroups: editableConfigData?.excludedGroups,
      allowLateSubmission: editableConfigData?.allowLateSubmission,
      activeCustomFrequency: editableConfigData?.activeCustomFrequency,
      visibility: editableConfigData?.visibility,
      managersCanAddQuestions: editableConfigData?.managersCanAddQuestions,

      generalFrequency: {
        week: editableConfigData?.generalFrequency?.week,
        frequency: editableConfigData?.generalFrequency?.frequency || 'weekly',
        activeCheckins: editableConfigData?.generalFrequency?.activeCheckins,
      },
    });
  }, [
    reset,
    editableConfigData?.excludedGroups,
    editableConfigData?.allowLateSubmission,
    editableConfigData?.activeCustomFrequency,
    editableConfigData?.visibility,
    editableConfigData?.managersCanAddQuestions,
    editableConfigData?.generalFrequency?.week,
    editableConfigData?.generalFrequency?.frequency,
    editableConfigData?.generalFrequency?.activeCheckins,
  ]);

  useEffect(() => {
    insertDefaultData();
  }, [configuration, editableConfigData, insertDefaultData]);

  const submitForm = useCallback(
    async (data: WorkspaceCheckinsSettingsForm) => {
      enableCheckins();

      setDisabled(true);

      const data__ = {
        ...data,
        allowLateSubmission: data?.allowLateSubmission
          ? data?.allowLateSubmission
          : false,
        managersCanAddQuestions: !!data?.managersCanAddQuestions ? true : false,
        activeCustomFrequency: !!data?.activeCustomFrequency ? true : false,
        generalFrequency: {
          week: data.generalFrequency?.week || null,
          frequency: data.generalFrequency?.frequency,
          activeCheckins: data.generalFrequency?.activeCheckins,
        },
      };

      const response = await workspaceCheckinsController.updateCheckinsSettings(
        data__,
      );

      if (response) {
        setCheckinsConfiguration(data__);
        setEditableConfigData(data__);
      }
    },
    [enableCheckins, setCheckinsConfiguration, workspaceCheckinsController],
  );
  const submitEditedForm = useCallback(
    (data: WorkspaceCheckinsSettingsForm) => {},
    [],
  );
  const editCheckins = () => {
    setIsConfiguring(true);
    navigate('checkins-configure');
  };

  const handleToggleCheckins = async (e: any, status: string) => {
    const response = await workspaceAPIController.updateWorkspace({
      settings: {
        checkInStatus: status,
      },
    });
    response &&
      setAuthResponse({
        ...auth,
        user: {
          ...auth?.user,
          workspace: {
            ...auth?.user?.workspace,
            settings: {
              ...auth?.user?.workspace?.settings,
              checkInStatus: status,
            },
          },
        },
      });
  };

  //
  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const grpRequest = new AnalyticsRequestImpl();
    const grpController = new AnalyticsController(grpRequest);
    const groupsResponse = await grpController.fetchGroups();

    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    isSubmitting,
    handleFormValueChange,
    isEditingCheckinsFrequency,
    setIsEditingCheckinsFrequency,
    handleEditCheckinsFrequency,
    handleSaveCheckinsFrequency,
    isEditingCheckinsDueDayFrequency,
    handleEditCheckinsDueDayFrequency,
    handleSaveCheckinsDueDayFrequency,
    advancedSettingsExpanded,
    setAdvancedSettingsExpanded,
    togglCustomFrequency,
    setTogglCustomFrequency,
    selectedVisibility,
    disabled,
    setSelectedVisibility,
    isConfiguring,
    firstTimeEnable,
    isCheckinsEnabled,
    setIsConfiguring,
    enableCheckins,
    editCheckins,
    handleToggleCheckins,
    submitForm,
    submitEditedForm,
    handleActiveCheckins,
    isEditingCustomCheckinsFrequency,
    setIsEditingCustomCheckinsFrequency,
    loading,
  };
}
